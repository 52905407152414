import {
  CreateReactorOptions,
  Properties,
  Reactor,
  ReactorFactory,
  ReactorId,
  ReactorObjectBase,
} from './reactor';

export const local = false;

export type EventType =
  | 'tick'
  | 'click'
  | 'pointerdown'
  | 'pointerup'
  | 'mousedown'
  | 'mouseup'
  | 'mousemove'
  | 'keydown'
  | 'keyup'
  | 'resize'
  | 'slideindexchange';

export interface Event {
  readonly type: EventType;
  stopPropagation(): void;
  preventDefault(): void;
}

export interface PointerEvent extends Event {
  // Mouse
  /*
  readonly altKey: boolean;
  readonly button: number;
  readonly buttons: number;
  readonly clientX: number;
  readonly clientY: number;
  readonly ctrlKey: boolean;
  readonly metaKey: boolean;
  readonly movementX: number;
  readonly movementY: number;
  readonly offsetX: number;
  readonly offsetY: number;
  readonly pageX: number;
  readonly pageY: number;
  readonly relatedTarget: EventTarget | null;
  readonly screenX: number;
  readonly screenY: number;
  readonly shiftKey: boolean;
  */
  readonly x: number;
  readonly y: number;

  // Pointer
  /*
  readonly height: number;
  readonly isPrimary: boolean;
  readonly pointerId: number;
  readonly pointerType: string;
  readonly pressure: number;
  readonly tangentialPressure: number;
  readonly tiltX: number;
  readonly tiltY: number;
  readonly twist: number;
  readonly width: number;
  */
}

export interface KeyboardEvent extends Event {
  readonly code: string;
  readonly key: string;
  readonly ctrlKey: boolean;
  readonly altKey: boolean;
  readonly shiftKey: boolean;
  readonly repeat: boolean;
}

export type Template = { [property: string]: any };

// TODO: visual (will cause re-rendering)
export type PropertyInfo = { default: any; inherit: boolean };

export type ObjectFitType =
  | 'fill' /* default, aka stretch */
  | 'contain'
  | 'cover'
  | 'none'
  | 'scale-down';

export type ViewportFitType =
  | 'scale' /* default */
  | 'resize'
  | 'vertical-scroll'
  | 'horizontal-scroll'
  | 'scroll';
export type ImageRenderingType = /* default, aka smooth */ 'normal' | 'pixelated';
export type HorizontalAlignmentType = 'left' | 'right' | 'center' | 'justify';
export type VerticalAlignmentType = 'bottom' | 'center' | 'top';
export type OverflowType = 'hidden' | 'visible';
export type LayoutType = /* default */ 'positioned' | 'flex';
export type FlexDirection = /* default */ 'column' | 'row' | 'column-reverse' | 'row-reverse';

// Of the format "#rrggbb" or "#rrggbbaa"
export type Color = string;

export type ParagraphStyle = {
  fontFamily?: string;
  fontTypeface?: string;
  fontSize?: number;
  horizontalAlignment?: HorizontalAlignmentType;
  beforeParagraph?: number;
  color?: Color;
};

// Accepts resource ids, possibly prefixed with "resource:" and returns
// a URL the resource can be fetched from.
export function getResourceUrl(resid: string): string {
  if (resid.startsWith('resource:')) {
    resid = resid.split(':', 2)[1];
  }
  return local ? `/backend/resource/${resid}` : `https://resources.viz.site/${resid}`;
}

// TODO: how to share this type with server?
// TODO: replace name, title with manifest.name?
// TODO: replace preview with manifest.icons?
export type ProjectInfo = {
  id: ProjectId; // Unique across all projects
  owner: UserId; // User id of the project's owner
  ownerName: string; // Username of the project's owner
  name: string;
  title: string;
  template: ProjectId; // Project id of the template this project is derived from
  created: number; // Timestamp (e.g. Date.now() or firebase.database.ServerValue.TIMESTAMP)
  modified: number; // Timestamp (e.g. Date.now() or firebase.database.ServerValue.TIMESTAMP)
  deck: ResourceId; // ResourceId of the project data
  preview: string; // A resource: URI
  sharing: string; // 'public' | 'private'. TODO: 'userA,userB,userC'
  version: number; // Incremented at save time
  publishedDeck?: ResourceId; // ResourceId of the published project data
  publishedVersion?: number;
  published?: number; // A timestamp, like created, modified.
  // Array of ResourceIds of previous (and current) published projects in time ascending order.
  publishedHistory?: string[];
  // TODO: publishedDate?: string[]; // to published as publishedHistory is to publishedDeck
  // TODO: create an index for important known tags and at some point maintain a tag->projectId mapping.
  // Reserved tags: _template
  tags?: { [tag: string]: boolean | null };
  manifest?: WebAppManifest;
};

export type ImportInfo = {
  // Stored properties.
  name: string;
  source: string /*, priority: number */;
  _projectHash?: string;
  projectVersion?: number;

  // Runtime properties.
  promise?: Promise<any>;
  module?: any;
  importedProject?: Project;
  importedName?: string;
  importedSource?: string;
};

export type ProjectId = string;
export type ResourceId = string;
export type UserId = string;

export interface Workbench {}

// Progressive Web Application (PWA) stuff
// TODO: would be nice to get this from some place official
type TextDirection = 'auto' | 'ltr' | 'rtl';
type DisplayMode = 'browser' | 'fullscreen' | 'standalone' | 'minimal-ui';

type ImageResource = {
  src?: string;
  sizes?: string;
  type?: string;
  purpose?: string;
  platform?: string;
};

type ExternalApplicationResource = {
  platform?: string;
  url?: string;
  id?: string;
  min_version?: string;
  fingerprints?: Fingerprint[];
};

type Fingerprint = {
  type: string;
  value: string;
};

type ShortcutItem = {
  name: string;
  short_name?: string;
  description?: string;
  url: string;
  icons?: ImageResource[];
};

type WebAppManifest = {
  dir?: TextDirection;
  lang?: string;
  name?: string;
  short_name?: string;
  description?: string;
  icons?: ImageResource[];
  screenshots?: ImageResource[];
  categories?: string[];
  iarc_rating_id?: string;
  start_url?: string;
  display?: DisplayMode;
  orientation?: OrientationLockType;
  theme_color?: string;
  background_color?: string;
  scope?: string;
  related_applications?: ExternalApplicationResource[];
  prefer_related_applications?: boolean;
  shortcuts?: ShortcutItem[];
};

export type ProjectState = {
  manifest?: WebAppManifest;
  Imports?: ImportInfo[];
};

export interface Project extends ReactorObjectBase {
  reactorFactory: ReactorFactory;
  mainProject: Project;
  designMode: boolean;
  readonly info: ProjectInfo;

  manifest?: WebAppManifest;
  templatesRoot?: string;
  // TODO: designersRoot?
  designer?: string;
  Imports?: ImportInfo[];
  imports: Reactor;
  Workbench?: Reactor;

  initialize(reactorFactory: ReactorFactory, workbench?: Workbench): Promise<void>;
  createReactor(properties?: Properties, options?: CreateReactorOptions): Reactor;
  getReactorById(id: ReactorId): Reactor;
  getReactorByPath(path: string): Reactor | undefined;
  forEachReactor(callback: (reactor: Reactor) => boolean): void;
  dispose(): void;

  // TODO: move these to a View object?
  mount(container: HTMLElement): void;
  unmount(): void;
  isMounted: boolean;
  resize(width: number, height: number): void;
}

export interface Component extends ReactorObjectBase {
  type: string;
  name: string;
  deck: Deck;
  parent?: Component;
  components: PositionedComponent[];
  showNavigationControls: boolean;

  index: number; // TODO: !!!
}

export interface VisualComponent extends Component {
  width: number;
  height: number;
  color: Color | undefined;
  backgroundColor: Color | undefined;
  layoutType: LayoutType;
  flexDirection: FlexDirection;
  scrollTop: number;
  scrollLeft: number;

  getComponentAtPoint(
    x: number,
    y: number
  ): { component?: PositionedComponent; xOffset: number; yOffset: number };
}

export interface PositionedComponent extends VisualComponent {
  x: number;
  y: number;
  rotation: number;
  scale: number;
  opacity: number;
  visible: boolean;
}

/*
export interface Timer extends PositionedComponent {
  enabled: boolean;
  interval: number;

  onTick(listener: (event: VizEvent) => void): () => void;
}
*/

export interface Slide extends PositionedComponent {}

export interface Deck extends PositionedComponent {
  viewportFit: ViewportFitType;
  slides: Component[];
  activeSlide: Slide;
  slideIndex: number;

  resize(viewportWidth: number, viewportHeight: number): void;
  getTemplateNames(): string[];

  onResize(listener: (event: Event) => void): () => void;

  mounted: boolean; // TODO: !!!
  mount(element: HTMLElement): void;
  unmount(): void;
}

export interface VizEvent {
  defaultPrevented: boolean;
  stopPropagation(): void;
  preventDefault(): void;
}
