import * as paths from './paths';

export interface Retainable {
  retain(): void;
  release(): void;
}

export interface Stat {
  isDir: boolean;
}

// readdir returns this. Returning stat too speeds up some usage cases (walkTree, fs copy)
export interface DirectoryEntry {
  name: string;
  stat: Stat;
}

export interface Watcher {
  close(): void;
}

export interface AsyncWatcher {
  close(): Promise<void>;
}

export enum FileOperation {
  CREATE,
  DELETE,
  RENAME,
  UPDATE,
}

export type WalkInfo = {
  path: string;
  stat: Stat;
  hasChildren: boolean;
};

export type FileUpdate = {
  op: FileOperation;
  path: string;
  oldPath?: string;
};

export interface AsyncFileSystem {
  stat(path: string): Promise<Stat>;
  readFile(path: string): Promise<FSData>;
  writeFile(path: string, data: string | ArrayBuffer | FSData): Promise<boolean>;
  removeFile(path: string): Promise<boolean>;
  readdir(path: string): Promise<DirectoryEntry[]>;
  mkdir(path: string): Promise<boolean>;
  rmdir(path: string): Promise<boolean>;
  rename(oldPath: string, newPath: string): Promise<boolean>;
  watch(callback: (updates: FileUpdate[]) => Promise<void>): Promise<AsyncWatcher>;
  walkTree(path: string, filesOnly: boolean): Promise<WalkInfo[]>;
  copyDir(
    srcPath: string,
    dstPath: string,
    srcFs?: AsyncFileSystem,
    progress?: (percent: number) => Promise<void>
  ): Promise<boolean>;
  dispose(): Promise<void>;
  dump(): Promise<void>;
}

// Return a proxy for an AsyncFileSystem that only exposes the public API and retains/releases callbacks.
export function getFileSystemAPI(fs: AsyncFileSystem): AsyncFileSystem {
  return {
    stat: (path) => fs.stat(path),
    readFile: async (path) => {
      const fsdata = await fs.readFile(path);
      // FSData has getter functions which can't be remoted.
      // TODO: this is inefficient because 1) unnecessary conversion is performed, 2) data is returned
      // multiple times. Return the string | ArrayBuffer (or single ArrayBuffer representation) and
      // leave conversion to the caller.
      let str;
      try {
        str = fsdata.string;
      } catch {
        str = null;
      }
      return {
        data: fsdata.data,
        buffer: fsdata.buffer,
        string: str,
        isBuffer: fsdata.isBuffer,
        isString: fsdata.isString,
      };
    },
    writeFile: (path, data) => fs.writeFile(path, data),
    removeFile: (path) => fs.removeFile(path),
    readdir: (path) => fs.readdir(path),
    mkdir: (path) => fs.mkdir(path),
    rmdir: (path) => fs.rmdir(path),
    rename: (oldPath, newPath) => fs.rename(oldPath, newPath),
    watch: async (callback: ((updates: FileUpdate[]) => Promise<void>) & Retainable) => {
      if (callback.retain) callback.retain();
      const watcher = await fs.watch(callback);
      return {
        close: async () => {
          watcher.close();
          if (callback.release) callback.release();
        },
      };
    },
    walkTree: (path: string, filesOnly: boolean) => fs.walkTree(path, filesOnly),
    copyDir: async (
      srcPath: string,
      dstPath: string,
      srcFs?: AsyncFileSystem,
      progress?: (percent: number) => Promise<void>
    ) => fs.copyDir(srcPath, dstPath, srcFs, progress),
    dispose: () => fs.dispose(),
    dump: () => fs.dump(),
  };
}

export abstract class BaseAsyncFileSystem implements AsyncFileSystem {
  private watcherList = new AsyncWatcherList<FileUpdate[]>();

  public abstract stat(path: string): Promise<Stat>;
  public abstract readFile(path: string): Promise<FSData>;
  public abstract writeFile(path: string, data: string | ArrayBuffer | FSData): Promise<boolean>;
  public abstract removeFile(path: string): Promise<boolean>;
  public abstract readdir(path: string): Promise<DirectoryEntry[]>;
  public abstract mkdir(path: string): Promise<boolean>;
  public abstract rmdir(path: string): Promise<boolean>;
  public abstract rename(oldPath: string, newPath: string): Promise<boolean>;

  public async watch(callback: (updates: FileUpdate[]) => Promise<void>): Promise<AsyncWatcher> {
    return this.watcherList.newWatcher(callback);
  }

  protected async notify(updates: FileUpdate | FileUpdate[]): Promise<void> {
    if (!(updates instanceof Array)) {
      updates = [updates];
    }
    return this.watcherList.notify(updates);
  }

  public async walkTree(path: string, filesOnly: boolean): Promise<WalkInfo[]> {
    const infos: WalkInfo[] = [];
    await this.walkTreeHelper(path, filesOnly, infos, true);
    return infos;
  }

  private async walkTreeHelper(
    path: string,
    filesOnly: boolean,
    infos: WalkInfo[],
    isRoot: boolean
  ): Promise<void> {
    const entries = await this.readdir(path);
    if (!filesOnly && !isRoot) {
      infos.push({ path, stat: await this.stat(path), hasChildren: entries && entries.length > 0 });
    }
    if (!entries) {
      return;
    }

    for (const entry of entries) {
      const childPath = paths.join(path, entry.name);
      if (entry.stat.isDir) {
        await this.walkTreeHelper(childPath, filesOnly, infos, false);
      } else {
        infos.push({ path: childPath, stat: entry.stat, hasChildren: false });
      }
    }
  }

  public async copyDir(
    srcPath: string,
    dstPath: string,
    srcFs?: AsyncFileSystem,
    progress?: (percent: number) => void
  ): Promise<boolean> {
    // By default, copy within same fs
    if (!srcFs) {
      srcFs = this;
    }

    // Count how many files there are
    const infos = await srcFs.walkTree(srcPath, false);
    let fileCount = 0;
    for (const { stat } of infos) {
      if (!stat.isDir) {
        fileCount++;
      }
    }

    // Copy over the tree
    let fileIndex = 0;
    let timeLast = Date.now();
    for (const { path, stat, hasChildren } of infos) {
      const outPath = paths.join(dstPath, path.slice(srcPath.length));
      if (stat.isDir) {
        if (!hasChildren) {
          // Only need to mkdir when there are no children. When there are chldren,
          // writeFile will make directories as needed.
          await this.mkdir(outPath);
        }
      } else {
        const data = await srcFs.readFile(path);
        await this.writeFile(outPath, data);
        if (progress) {
          const percentage = (++fileIndex / fileCount) * 100;
          if (fileIndex === fileCount || Date.now() - timeLast >= 200) {
            progress(percentage);
            timeLast = Date.now();
          }
        }
      }
    }
    return true;
  }

  public async dispose(): Promise<void> {
    return this.watcherList.dispose();
  }

  public async dump(): Promise<void> {
    for (const { path, stat } of await this.walkTree('', false)) {
      console.log(path + (stat.isDir ? '/' : ''));
    }
  }
}

export interface SyncFileSystem {
  stat(path: string): Stat;
  readFile(path: string): FSData;
  writeFile(path: string, data: string | ArrayBuffer | FSData): boolean;
  removeFile(path: string): boolean;
  readdir(path: string): DirectoryEntry[];
  mkdir(path: string): boolean;
  rmdir(path: string): boolean;
  rename(oldPath: string, newPath: string): boolean;
  watch(callback: (update: FileUpdate[]) => void): Watcher;
  walkTree(path: string, filesOnly: boolean): WalkInfo[];
  copyDir(
    srcPath: string,
    dstPath: string,
    srcFs?: SyncFileSystem,
    progress?: (percent: number) => void
  ): boolean;
  dispose(): Promise<void>;
  dump(): void;
}

export abstract class BaseSyncFileSystem {
  private watcherList = new WatcherList<FileUpdate[]>();

  public abstract stat(path: string): Stat;
  public abstract readFile(path: string): FSData;
  public abstract writeFile(path: string, data: string | ArrayBuffer | FSData): boolean;
  public abstract removeFile(path: string): boolean;
  public abstract readdir(path: string): DirectoryEntry[];
  public abstract mkdir(path: string): boolean;
  public abstract rmdir(path: string): boolean;
  public abstract rename(oldPath: string, newPath: string): boolean;

  public watch(callback: (update: FileUpdate[]) => void): Watcher {
    return this.watcherList.newWatcher(callback);
  }

  protected notify(updates: FileUpdate | FileUpdate[]): void {
    if (!(updates instanceof Array)) {
      updates = [updates];
    }
    return this.watcherList.notify(updates);
  }

  public walkTree(path: string, filesOnly: boolean): WalkInfo[] {
    const infos: WalkInfo[] = [];
    this.walkTreeHelper(path, filesOnly, infos, true);
    return infos;
  }

  private walkTreeHelper(
    path: string,
    filesOnly: boolean,
    infos: WalkInfo[],
    isRoot: boolean
  ): void {
    const entries = this.readdir(path);
    if (!filesOnly && !isRoot) {
      infos.push({ path, stat: this.stat(path), hasChildren: entries && entries.length > 0 });
    }
    if (!entries) {
      return;
    }

    for (const entry of entries) {
      const childPath = paths.join(path, entry.name);
      if (entry.stat.isDir) {
        this.walkTreeHelper(childPath, filesOnly, infos, false);
      } else {
        infos.push({ path: childPath, stat: entry.stat, hasChildren: false });
      }
    }
  }

  public copyDir(
    srcPath: string,
    dstPath: string,
    srcFs?: SyncFileSystem,
    progress?: (percent: number) => void
  ): boolean {
    // By default, copy within same fs
    if (!srcFs) {
      srcFs = this;
    }

    // Count how many files there are
    const infos = srcFs.walkTree(srcPath, false);
    let fileCount = 0;
    for (const { stat } of infos) {
      if (!stat.isDir) {
        fileCount++;
      }
    }

    // Copy over the tree
    let fileIndex = 0;
    let timeLast = Date.now();
    for (const { path, stat, hasChildren } of infos) {
      const outPath = paths.join(dstPath, path.slice(srcPath.length));
      if (stat.isDir) {
        if (!hasChildren) {
          // Only need to mkdir when there are no children. When there are chldren,
          // writeFile will make directories as needed.
          if (!this.mkdir(outPath)) {
            return false;
          }
        }
      } else {
        const data = srcFs.readFile(path);
        if (!data) {
          return false;
        }
        if (!this.writeFile(outPath, data)) {
          return false;
        }
        if (progress) {
          const percentage = (++fileIndex / fileCount) * 100;
          if (fileIndex === fileCount || Date.now() - timeLast >= 200) {
            progress(percentage);
            timeLast = Date.now();
          }
        }
      }
    }
    return true;
  }

  public async dispose(): Promise<void> {
    await this.watcherList.dispose();
  }

  public dump(): void {
    for (const { path, stat } of this.walkTree('', false)) {
      console.log(path + (stat.isDir ? '/' : ''));
    }
  }
}

// BuildFileSystem is an AsyncFileSystem with the requirement of sync access to typescript files.
export interface BuildFileSystem extends AsyncFileSystem {
  getSyncCompilerFS(): SyncFileSystem;
  getSyncUnderlayFS(): SyncFileSystem;
}

// FSData wraps typed FS data to minimize conversions. Purposefully does not implement a buffer like interface itself.
export class FSData {
  constructor(public data: string | ArrayBuffer) {}

  get buffer(): ArrayBuffer {
    if (typeof this.data === 'string') {
      // There is a native encoding library standard but it isn't supported on all browsers yet. For now we're using:
      // https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
      // Note it would be more accurate to convert to a utf-8 byte stream.
      const buf = new ArrayBuffer(this.data.length * 2);
      const bufView = new Uint16Array(buf);
      for (let i = 0, strLen = this.data.length; i < strLen; i++) {
        bufView[i] = this.data.charCodeAt(i);
      }
      return buf;
    } else {
      return this.data;
    }
  }

  get string(): string | null {
    if (typeof this.data === 'string') {
      return this.data;
    } else {
      // https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
      // This is native and fast but expects utf-16 characters as input.
      return String.fromCharCode.apply(null, new Uint16Array(this.data) as any);
    }
  }

  get isString(): boolean {
    return typeof this.data === 'string';
  }

  get isBuffer(): boolean {
    return typeof this.data !== 'string';
  }
}

export class FSHelpers {
  public static getPathParts(path: string): string[] {
    // The path is always absolute.
    const parts = paths.normalize(path, false).split('/');

    // If the path starts with /, throw an exception
    if (path.length > 0 && path[0] === '/') {
      throw new Error('by convention, paths do not start with /');
    }

    // Remove trailing slash if it exists
    if (parts.length > 0 && parts[parts.length - 1] === '') {
      parts.pop();
    }

    return parts;
  }

  public static normalizePath(path: string): string {
    if (path[0] === '/') {
      path = path.slice(1);
    }
    return FSHelpers.getPathParts(path).join('/');
  }
}

export class WatcherList<T> {
  private callbacks: ((item: T) => void)[] = [];

  public newWatcher(callback: (item: T) => void): Watcher {
    this.callbacks.push(callback);
    return { close: this.closeWatcher.bind(this, callback) };
  }

  public closeWatcher(callback: (item: T) => void): void {
    const i = this.callbacks.indexOf(callback);
    if (i !== -1) {
      this.callbacks.splice(i, 1);
    }
  }

  public notify(item: T): void {
    // Slice to be immune from list mutations during notification callbacks.
    for (const callback of this.callbacks.slice()) {
      try {
        callback(item);
      } catch (err) {
        // ignore
      }
    }
  }

  public async dispose(): Promise<void> {
    this.callbacks = [];
  }
}

export class AsyncWatcherList<T> {
  private callbacks: ((item: T) => Promise<void>)[] = [];

  public async newWatcher(callback: (item: T) => Promise<void>): Promise<AsyncWatcher> {
    this.callbacks.push(callback);
    return { close: this.closeWatcher.bind(this, callback) };
  }

  public async closeWatcher(callback: (item: T) => Promise<void>): Promise<void> {
    const i = this.callbacks.indexOf(callback);
    if (i !== -1) {
      this.callbacks.splice(i, 1);
    }
  }

  public getCount(): number {
    return this.callbacks.length;
  }

  public async notify(item: T): Promise<void> {
    // Slice to be immune from list mutations during notification callbacks.
    for (const callback of this.callbacks.slice()) {
      try {
        await callback(item);
      } catch (err) {
        // ignore
      }
    }
  }

  public async dispose(): Promise<void> {
    this.callbacks = [];
  }
}

export function isBinary(fileName: string, mimeType?: string): boolean {
  if (
    mimeType &&
    (mimeType.startsWith('image/') ||
      mimeType.startsWith('audio/') ||
      mimeType.startsWith('video/') ||
      mimeType.startsWith('font/') ||
      mimeType.startsWith('application/x-font') ||
      mimeType.startsWith('application/font') ||
      mimeType === 'application/vnd.ms-fontobject' ||
      mimeType === 'application/zip' ||
      mimeType === 'application/octet-stream' ||
      mimeType === 'application/octet-binary')
  )
    return true;

  const ext = paths.extname(fileName).toLowerCase().slice(1); // Trim '.' prefix. Works even if extname is ''.
  return (
    [
      'jpg',
      'jpeg',
      'jpe',
      'png',
      'gif',
      'psd',
      'tif',
      'tiff',
      'svg',
      'bmp',
      'ico',
      'webp',
      'wav',
      'mp3',
      'ogg',
      'aac',
      'mid',
      'midi',
      'weba',
      'ogx',
      'mpg',
      'mpeg',
      'mp4',
      'avi',
      'ogv',
      'swf',
      'webm',
      '3gp',
      '3g2',
      'woff',
      'woff2',
      'ttf',
      'eot',
      'otf',
      'xls',
      'doc',
      'ppt',
      'vsd',
      'zip',
      'pdf',
      'bin',
      'gz',
      'rar',
      '7z',
      'arc',
      'bz',
      'bz2',
      'exe',
    ].indexOf(ext) !== -1
  );
}
