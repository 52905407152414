// TODO: URL changes to support back button and sharing
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { pushHistoryState, UserContext } from './App';
import { useTitle } from './hooks/handyHooks';
import { createPlayer, Player } from './player';
import { ProjectStore } from './projectStorage';
import { ProjectInfo, ProjectState } from './runtime';

const useStyles = makeStyles((theme) =>
  createStyles({
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    progress: {
      margin: theme.spacing(2),
    },
    controls: {
      zIndex: 100,
      position: 'absolute',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      filter: 'drop-shadow(0px 2px 0px white)',
      pointerEvents: 'none',
    },
    button: {
      pointerEvents: 'auto',
    },
    iframe: {
      height: '100%',
      border: 0,
    },
  })
);

export type ProjectPlayerProps = {
  projectInfo: ProjectInfo;
  projectStore: ProjectStore;
  onEdit(projectInfo: ProjectInfo): void;
  onClose(): void;
};

export const ProjectPlayer: React.FC<ProjectPlayerProps> = (props) => {
  const classes = useStyles();
  const { projectInfo, projectStore, onEdit, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [projectState, setProjectState] = useState<ProjectState>();
  const playerRef = useRef<Player>();
  const user = useContext(UserContext);

  useTitle(projectInfo.title);

  // The ProjectPlayer adds itself to the browser history. This function is called by explicit
  // 'close'es to remove it from the history. This covers the cases the brower back button doesn't.
  const popAndClose = () => {
    window.history.back();
    onClose();
  };

  // And the ProjectPlayer to the browser history so the back button does what people expect.
  useEffect(() => {
    function onPopState(): void {
      onClose();
    }
    window.addEventListener('popstate', onPopState);

    // Display a URL that if copied, shared, and used will bring people to the publisehd project.
    // TODO: ...but it may not be pubished. Maybe we want to come back to viz.site/gallery/<playthisproject>?
    pushHistoryState(undefined, '', `/p/${projectInfo.ownerName}/${projectInfo.id}`);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
    // This (must) only execute once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.id === projectInfo.owner || projectInfo.sharing === 'public') {
      setIsLoading(true);
      projectStore.readProject(projectInfo).then((projectState) => {
        setIsLoading(false);
        setProjectState(projectState);
      });
      // TODO: read error
    } else {
      // We're displaying the project's published site via iframe.
      setIsLoading(true);
    }
  }, [user, projectInfo, projectStore]);

  useEffect(() => {
    return () => {
      playerRef.current?.dispose();
    };
  }, [playerRef]);

  const projectContainerRef = useCallback<(element: HTMLDivElement) => void>(
    async (element) => {
      if (element !== null && projectState) {
        const player = await createPlayer(
          projectState,
          projectInfo,
          element,
          console,
          true,
          'hidden'
        );
        playerRef.current = player;
      }
    },
    [projectState, projectInfo]
  );

  return (
    <Dialog fullScreen open={true} onClose={popAndClose}>
      <div className={classes.controls}>
        {(projectInfo.owner === user.id || projectInfo.sharing === 'public') && (
          <IconButton
            className={classes.button}
            onClick={() => onEdit(projectInfo)}
            aria-label='edit'
          >
            <EditIcon />
          </IconButton>
        )}
        <IconButton className={classes.button} onClick={() => popAndClose()} aria-label='close'>
          <CloseIcon />
        </IconButton>
      </div>
      {isLoading && (
        <div className={classes.loading}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
      {user.id === projectInfo.owner || projectInfo.sharing === 'public' ? (
        !isLoading &&
        projectState && (
          <div
            ref={projectContainerRef}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              overflow: 'auto',
            }}
          />
        )
      ) : (
        // TODO: load error
        // TODO: does this case (a private AND published project) happen?
        <iframe
          title={projectInfo.title}
          className={classes.iframe}
          src={`/p/${projectInfo.ownerName}/${projectInfo.id}`}
          style={{ display: isLoading ? 'none' : 'block' }}
          onLoad={() => setIsLoading(false)}
        ></iframe>
      )}
    </Dialog>
  );
};

export default ProjectPlayer; // For lazy loading.
