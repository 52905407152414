import { loadProject, OverflowType, Project, ProjectInfo, ProjectState } from './runtime';

import { deserialize } from './runtime/importer';
export { deserialize };

export const defaultBackgroundColor = '#282c34';

export async function createPlayer(
  state: ProjectState,
  info: ProjectInfo,
  containerOrId?: HTMLElement | string,
  console?: Console,
  setBackgroundColor = false,
  overflow: OverflowType = 'hidden'
): Promise<Player> {
  const player = new Player(state, info, console, containerOrId, overflow, setBackgroundColor);
  await player.asyncInitialize();
  return player;
}

export class Player {
  project!: Project;

  private console: Console;
  private container: HTMLElement;

  constructor(
    private state: ProjectState,
    private info: ProjectInfo,
    console?: Console,
    containerOrId?: HTMLElement | string,
    overflow: OverflowType = 'hidden',
    private setBackgroundColor = false
  ) {
    this.console = console ?? window.console;
    if (typeof containerOrId === 'string') {
      this.container = document.getElementById(containerOrId)!;
    }
    if (containerOrId !== undefined) {
      this.container = containerOrId as HTMLElement;
    } else {
      this.container = document.body;
    }
  }

  async asyncInitialize(): Promise<void> {
    const project = (this.project = await loadProject(this.state, this.info));
    try {
      //console.log('mount Player project');
      project.mount?.(this.container);
    } catch (err) {}
  }

  dispose() {
    try {
      //console.log('dispose Player project');
      this.project?.dispose(); // dispose unmounts
    } catch (err) {}
  }
}
