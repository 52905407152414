// Reactor
// - encapsulates state and exposes as expression ("$" prefixed) and value properties
// - evaluates expression properties to produce value properties
// - tracks dirty state (invalidate, validate, update)
// - supports prototypal multiple inheritance via the templates array ("template inheritance")
// - property changes can be observed
// TODO: - re-evaluates (reacts) when dependent values change
// TODO: - re-evaluates (reacts) when inherited values change
//  --- kind if different, right? considered 'changed'
//  - maybe makes sense to separate?
// TODO: - changes to inherited properties are seen as property changes on observed children

import { createReactorArray } from './reactorArray';
import { createReactorObject } from './reactorObject';

// Register our Reactor debug formatter with DevTools.
registerReactorFormatter();

export const defaultTemplatesRoot = 'Templates';

export type ReactorId = number;

export type CreateReactorOptions = {
  noId?: boolean;
  contextualName?: string;
  updateMethods?(reactor: Reactor): void;
};

export type CloneReactorOptions = {
  contextualName?: string;
};

export type ChangeType = 'add' | 'remove' | 'change';
export type ChangeListener = (
  reactor: Reactor,
  property: PropertyKey,
  newValue: any,
  oldValue: any,
  type: ChangeType
) => void;
export type Properties = { [property: string]: any };

export interface IDisposable {
  dispose(): void;
}

// Shared by ReactorObject and ReactorArray // TODO: -> Reactor?
export interface ReactorBase extends IDisposable {
  readonly id: ReactorId;

  getState(): Properties;
  onPropertyChange(listener: ChangeListener): IDisposable;
}

export interface ReactorArrayBase extends ReactorBase {}

export interface ReactorArray<T = any> extends ReactorArrayBase, Array<T> {
  [property: number]: T;
}

// Public interface
export interface ReactorObjectBase extends ReactorBase {
  readonly __contextualName: string;

  validate(): void;
  invalidate(property?: string): void;
  update?(dirty: Properties): void;
  clearDirty(): void;

  //getOwnProperty(property: PropertyKey): any | undefined;
  //getProperty(property: PropertyKey): any | undefined;
  getOwnExpressions(): Properties;
  getExpressions(): Properties;

  evaluateExpressionProperties(): void;

  initialize?(...args: any[]): void; // TODO: return Promise | undefined?
}

export interface ReactorObject extends ReactorObjectBase {
  [property: string]: any;
}

export interface Reactor extends ReactorBase {
  [property: number]: any;
  [property: string]: any;
}

export function isReactor(object: any): boolean {
  if (!object) {
    return false;
  }
  return object._isReactor === true;
}

export function createReactorFactory(nextReactorId?: number): ReactorFactory {
  return new ReactorFactory(nextReactorId);
}

// Keep track of dirty templates as they're encountered to speed up subsequent checks.
export const templateIsDirty = new Map<ReactorObject, boolean>();

export class ReactorFactory {
  /** @internal */
  nextReactorId: number;

  /** @internal */
  reactors: { [id: number]: Reactor | undefined } = {
    0: { id: -1, name: 'invalid Reactor' } as any,
  };

  /** @internal */
  rootReactor!: ReactorObject;

  constructor(nextReactorId: number = 1, private getExpressionScope?: () => any) {
    this.nextReactorId = nextReactorId;
  }

  clearDirtyTemplateCache(): void {
    for (const [template, isDirty] of templateIsDirty) {
      if (isDirty) {
        template.clearDirty();
      }
    }
    templateIsDirty.clear();
  }

  // TODO: dispose()?

  getNextReactorId(): ReactorId {
    return this.nextReactorId;
  }

  getReactorById(id: ReactorId): Reactor {
    const reactor = this.reactors[id];
    console.assert(reactor !== undefined, `reactor id ${id} isn't in reactor index`);
    if (!reactor) {
      throw new Error(`reactor id ${id} isn't in reactor index`);
    }
    return reactor;
  }

  // Reactor paths are dot-separated property names, e.g. Templates.View.5.whatever
  getReactorByPath(path: string): Reactor | undefined {
    const segments = path.split('.');
    let reactor = this.rootReactor;
    if (path === '') {
      return reactor;
    }
    for (const segment of segments) {
      const r = reactor[segment];
      if (r === undefined) {
        return undefined; // Path not found
      }
      reactor = r;
    }
    return reactor;
  }

  // [<importedProjectName>/].<ReactorId>
  // [<importedProjectName>/]<ReactorPath>
  getTemplate(templateName: string): ReactorObject | undefined {
    let project = this.rootReactor;

    // Template name can be prefixed with an imported project name.
    const { importName, unqualifiedName } = parseTemplateName(templateName);
    if (importName) {
      // Template is in an imported project.
      templateName = unqualifiedName;

      project = project.imports[importName];
      if (!project) {
        // Import can't be resolved (didn't load, has been removed, renamed, etc).
        return undefined;
      }
    }

    // Any Reactor can be a template referenced by its id prefixed with a ".".
    if (unqualifiedName[0] === '.') {
      const id = parseInt(unqualifiedName.slice(1));
      try {
        return project.getReactorById(id);
      } catch (err) {
        // It may no longer exist or may have been renamed. That's ok.
        return undefined;
      }
    } else {
      // Or templates are referenced by path.
      const templatesRoot = project.templatesRoot ?? defaultTemplatesRoot;
      const templatePath =
        templatesRoot === '' ? unqualifiedName : templatesRoot + '.' + unqualifiedName;
      return project.getReactorByPath(templatePath);
    }
  }

  /* TODO: nobody calling this yet
  evaluateExpressionProperties(): void {
    this.forEachReactor((reactor) => {
      if (!Array.isArray(reactor)) {
        (reactor as ReactorObject).evaluateExpressionProperties();
      }
      return true;
    });
  }
  */

  forEachReactor(callback: (reactor: Reactor) => boolean): void {
    for (const id in this.reactors) {
      const reactor = this.reactors[id];
      if (reactor && reactor.id !== -1) {
        if (!callback(reactor)) {
          // Abort enumeration when callback returns false.
          return;
        }
      }
    }
  }

  //
  //
  //

  createReactor<T = Reactor>(objectOrArray?: Properties | [], options?: CreateReactorOptions): T {
    if (Array.isArray(objectOrArray)) {
      return (createReactorArray(this, objectOrArray, options) as unknown) as T;
    } else {
      return (createReactorObject(this, objectOrArray, options) as unknown) as T;
    }
  }
}

export function parseTemplateName(
  qualifiedName: string
): {
  importName?: string;
  unqualifiedName: string;
} {
  if (qualifiedName.indexOf('/') === -1) {
    return { unqualifiedName: qualifiedName };
  } else {
    const [importName, unqualifiedName] = qualifiedName.split('/');
    return { importName, unqualifiedName };
  }
}

export function qualifyTemplateName(
  importName: string | undefined,
  unqualifiedName: string
): string {
  return importName !== undefined ? importName + '/' + unqualifiedName : unqualifiedName;
}

// Remove ids from an object hierarchy in place.
export function removeIds(state: Properties): Properties {
  delete state.id;
  delete state.__id;

  // Recurse on child objects and arrays;
  if (Array.isArray(state)) {
    for (const value of state) {
      if (Array.isArray(value) || typeof value === 'object') {
        removeIds(value);
      }
    }
  } else {
    for (const key in state) {
      const value = state[key];
      if (Array.isArray(value) || typeof value === 'object') {
        removeIds(value);
      }
    }
  }

  return state;
}

// Make the debugger display of Reactors a little nicer via a DevTools custom formatter.
// https://www.mattzeunert.com/2016/02/19/custom-chrome-devtools-object-formatters.html
export function registerReactorFormatter() {
  if (typeof window === 'undefined') {
    return;
  }

  // https://stackoverflow.com/questions/55733647/chrome-devtools-formatter-for-javascript-proxy
  if (!Array.isArray((window as any).devtoolsFormatters)) {
    (window as any).devtoolsFormatters = [];
  }

  //function jsonmlify(o: any): any {}

  (window as any).devtoolsFormatters.push({
    header(value: any) {
      if (!isReactor(value) || Array.isArray(value)) {
        return null;
      }

      const properties: Properties = { ...value };
      const short: Properties = { id: value.id };
      if (value.name) {
        short.name = value.name;
      }
      delete properties.id;
      delete properties.name;
      const keys = Object.keys(properties).slice(0, 5);
      keys.forEach((property) => (short[property] = properties[property]));

      return [
        'div',
        {},
        `{ ${Object.keys(short)
          .map((property) => {
            let v = value[property];
            switch (typeof v) {
              case 'object':
                if (isReactor(v)) {
                  v = `R[${v.id}]`;
                } else if (Array.isArray(v)) {
                  v = `Array`;
                } else {
                  v = `Object`;
                }
                break;

              case 'function':
                v = 'fn';
                break;

              default:
                v = JSON.stringify(v);
            }
            if (typeof v === 'string' && v.length > 10) {
              v = v.slice(0, 10) + '...';
            }
            return `${property}: ${v}`;
          })
          .join(', ')} }`,
      ];
    },

    hasBody() {
      return true;
    },

    body(value: any) {
      return [
        'ol',
        {
          style:
            'list-style-type:none; padding-left: 0px; margin-top: 4px; margin-bottom: 0px; margin-left: 12px',
        },
        ...Object.keys(value).map((property) => [
          'li',
          { style: 'padding-bottom: 4px; white-space: nowrap; color: #ccc' },
          ['span', { style: 'color: rgb(210, 120, 230)' }, property],
          ': ',
          ['object', { object: value[property] }],
        ]),
      ];
    },
  });
}
