import { WatcherList, Watcher } from './utils/util';

export interface Console {
  emit(e: ConsoleEvent): void;
}

export type ConsoleEvent = {
  method: 'input' | 'result' | 'log' | 'clear';
  data?: any;
};

export type ConsoleOutput = {
  events: ConsoleEvent[];
  history: ConsoleEvent[];
};

export type ConsoleInput = {
  input: string;
};

export class ConsoleService {
  private outputWatchers = new WatcherList<ConsoleOutput>();
  private inputWatchers = new WatcherList<ConsoleInput>();
  private toEmit: ConsoleEvent[] = [];
  private history: ConsoleEvent[] = [];
  private emitting = false;

  public watchOutput(callback: (output: ConsoleOutput) => void): Watcher {
    return this.wrapEmit(() => {
      callback({ events: [], history: this.history });
      return this.outputWatchers.newWatcher(callback);
    });
  }

  public watchInput(callback: (input: ConsoleInput) => void): Watcher {
    return this.inputWatchers.newWatcher(callback);
  }

  public onInput(input: string): void {
    this.inputWatchers.notify({ input });
  }

  public emit(event: ConsoleEvent | ConsoleEvent[]): void {
    this.wrapEmit(() => {
      if (event instanceof Array) {
        for (const e of event) {
          this.toEmit.push(e);
        }
      } else {
        this.toEmit.push(event);
      }
    });
  }

  private wrapEmit<T>(callback: () => T): T {
    const callWatchers = (): void => {
      // Handle clears and append to history
      const toEmit = this.toEmit;
      this.toEmit = [];
      for (let index = toEmit.length - 1; index >= 0; --index) {
        if (toEmit[index].method === 'clear') {
          toEmit.splice(0, index + 1);
          this.history = [];
          break;
        }
      }
      this.history.push(...toEmit);

      // TODO: Replace this arbitrary limit to keep from slowing everything down with something better.
      if (this.history.length > 50) {
        this.history.shift();
      }

      // Call watchers
      this.outputWatchers.notify({ events: toEmit, history: this.history });
      if (this.toEmit.length) {
        callWatchers();
      }
    };

    if (!this.emitting) {
      this.emitting = true;
      const result = callback();
      this.emitting = false;
      if (this.toEmit.length) {
        callWatchers();
      }
      return result;
    } else {
      return callback();
    }
  }

  public getConsole(): Console {
    return {
      emit: (e: ConsoleEvent) => this.emit(e),
    };
  }
}
