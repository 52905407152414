import { local } from './runtime';

const config = {
  apiKey: 'AIzaSyBha-eeW4SAAOlhpBtQTxqHwkEQVdJPJOc',
  authDomain: 'slyde-1.firebaseapp.com',
  databaseURL: 'https://slyde-1.firebaseio.com',
  projectId: 'slyde-1',
  storageBucket: 'slyde-1.appspot.com',
  messagingSenderId: '320780691602',
  appId: '1:320780691602:web:2d3f4adfd5b753f5',
};

/** The default address for the local database emulator. */
const DATABASE_ADDRESS: string = 'localhost:9000';

export let app: firebase.app.App | undefined;

// NOTE: TIMESTAMP, db are  initialized async so users of it must be certain
// that Firebase has already been initialized.
export let db: firebase.database.Database;
export let TIMESTAMP: Object;

async function getFirebase(): Promise<firebase.app.App> {
  if (app) {
    return app;
  }

  const firebase = await import(/* webpackChunkName: "FirebaseApp" */ 'firebase/app');

  await Promise.all([
    import(/* webpackChunkName: "FirebaseAuth" */ 'firebase/auth'),
    import(/* webpackChunkName: "FirebaseDb" */ 'firebase/database'),
  ]);

  function initializeOfflineApp(accessToken?: string, databaseName?: string): firebase.app.App {
    let appOptions: { [key: string]: string } = {
      apiKey: config.apiKey,
      projectId: config.projectId,
      databaseURL: `http://${DATABASE_ADDRESS}?ns=${databaseName}`,
    };
    const appName = 'app-' + new Date().getTime() + '-' + Math.random();
    const app = firebase.initializeApp(appOptions, appName);
    // hijacking INTERNAL.getToken to bypass FirebaseAuth and allows specifying of auth headers
    if (accessToken) {
      (app as any).INTERNAL.getToken = () => Promise.resolve({ accessToken: accessToken });
    }
    if (databaseName) {
      // Toggle network connectivity to force a reauthentication attempt.
      // This mitigates a minor race condition where the client can send the
      // first database request before authenticating.
      app.database().goOffline();
      app.database().goOnline();
    }
    return app;
  }

  app = local ? initializeOfflineApp('owner', config.projectId) : firebase.initializeApp(config);
  db = app.database();
  TIMESTAMP = firebase.database.ServerValue.TIMESTAMP;
  return app;
}

export async function getFirebaseAuth(): Promise<firebase.auth.Auth> {
  return (await getFirebase()).auth();
}

export async function getFirebaseDb(): Promise<firebase.database.Database> {
  return (await getFirebase()).database();
}
