import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import createMuiTheme, { Theme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import React, { createContext, lazy, Suspense, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, useLocation } from 'wouter';
import { ConsoleService } from './consoleService';
import { Div100vh } from './components/Div100vh';
import { getFirebaseAuth } from './firebase';
import { loadGlobalState } from './globalState';
import { ProjectStore } from './projectStorage';
import { ProjectInfo } from './runtime';
import './runtime/project.css';
import { ChooseTemplate } from './TemplateChooser';
import { User } from './user';
import { UserService } from './userService';
import { randomHSL } from './utils/util';
import logo from './Viz.png';

export const appName = 'Viz';

loadGlobalState();

// HACK: grab a reference to pushState before wouter monkey patches it.
export const pushHistoryState = window.history.pushState.bind(window.history);

const anonymousUser = { id: 'anonymous', name: 'anonymous', email: '' } as User;

type AppCommander = {
  newProject(): void;
  ToolBarPortal: React.FC;
};

export const consoleService = new ConsoleService();
export const userService = new UserService();
export const UserContext = createContext<User>(undefined as any);
export const AppContext = createContext<AppCommander>(undefined as any);

const ProjectExplorer = lazy(() =>
  import('./ProjectExplorer' /* webpackChunkName: "ProjectExplorer" */)
);
const Workbench = lazy(() =>
  import('./Workbench' /* webpackChunkName: "Workbench", webpackPrefetch: true */)
);
const UserPage = lazy(() => import('./UserPage' /* webpackChunkName: "UserPage" */));
const UsersPage = lazy(() => import('./UsersPage' /* webpackChunkName: "UsersPage" */));

function generateThemeOptions(): ThemeOptions {
  return {
    palette: {
      //type: 'dark',
      primary: {
        main: true ? randomHSL() : 'hsla(73,30%,80%,0.8)',
        contrastText: '#000',
      },
      secondary: {
        main: randomHSL(),
        contrastText: '#000',
      },
      tonalOffset: 0.3,
    },
  };
}

const defaultTheme = createMuiTheme(generateThemeOptions());

const useStyles = makeStyles((theme) =>
  createStyles({
    logoPlaceholder: {
      width: '60px',
    },
    toolBarPortal: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    appContainer: {
      background: theme.palette.primary.light,
      color: '#000',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      userSelect: 'none',
    },
  })
);

export const App: React.FC = () => {
  const [theme, setTheme] = useState(defaultTheme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContent setTheme={setTheme} />
    </ThemeProvider>
  );
};

export const AppContent: React.FC<{ setTheme: (theme: Theme) => void }> = (props) => {
  const { setTheme } = props;
  const classes = useStyles();
  const [user, setUser] = useState<User>();
  const [projectStore, setProjectStore] = useState<ProjectStore>();
  const [chooseTemplateOpen, setChooseTemplateOpen] = useState(false);
  const toolBarPortalElementRef = useRef<HTMLDivElement>(null);
  const [location, setLocation] = useLocation();
  const [auth, setAuth] = useState<firebase.auth.Auth>();

  // ProjectInfos are kept at the App level so they don't have to be reloaded e.g. as ProjectExplorer
  // comes and goes.
  const [projectInfos, setProjectInfos] = useState<ProjectInfo[]>([]);

  useEffect(() => {
    setTheme(createMuiTheme(user ? generateThemeOptions() : defaultTheme));
  }, [user, setTheme]);

  // Lazy load Firebase auth to get it out of the first bundle.
  useEffect(() => {
    getFirebaseAuth().then((authT) => setAuth(authT));
  }, []);

  useEffect(() => {
    if (!auth) {
      return;
    }

    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        // displayNames and emails can change but not uids.
        const user: User = {
          name: firebaseUser.displayName!,
          id: firebaseUser.uid,
          email: firebaseUser.email!,
        };
        setUser(user);
        /* Also might find uses for emailVerified, photoURL, isAnonymous, and providerData */
      } else {
        setUser(anonymousUser);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    userService.setCurrentUser(user);
  }, [user]);

  useEffect(() => {
    if (user) {
      let store: ProjectStore;
      setProjectInfos([]);
      store = new ProjectStore(user.id, false, (projectInfos) => {
        setProjectInfos(projectInfos);
      });
      setProjectStore(store);

      return () => store.close();
    }
  }, [user]);

  const ToolBarPortal: React.FC = (props) => (
    <Portal parentElement={toolBarPortalElementRef.current}>{props.children}</Portal>
  );

  const app: AppCommander = {
    newProject(): void {
      setChooseTemplateOpen(true);
    },
    ToolBarPortal,
  };

  return (
    <AppContext.Provider value={app}>
      <UserContext.Provider value={user as any}>
        <Div100vh className={classes.appContainer} style={{ height: '100rvh' }}>
          <AppBar position='relative' elevation={0}>
            <Toolbar variant='dense' disableGutters style={{ marginLeft: '16px' }}>
              <img
                src={logo}
                alt='Viz logo'
                width='60px'
                style={{ position: 'absolute', top: '0px' }}
              />
              <div className={classes.logoPlaceholder} />
              <div ref={toolBarPortalElementRef} className={classes.toolBarPortal} />
            </Toolbar>
          </AppBar>
          <Suspense fallback={null}>
            {user && (
              <Switch>
                {/* '/new': () => () => <ChooseTemplate ... */}
                <Route path='/u/:userName'>
                  {(params) => <UserPage userName={params.userName} projectStore={projectStore!} />}
                </Route>
                <Route path='/u'>{() => <UsersPage />}</Route>
                <Route path='/e/:deckId'>
                  {(params) => <Workbench projectStore={projectStore!} projectId={params.deckId} />}
                </Route>
                <Route path='/gallery'>
                  {() => (
                    <ProjectExplorer
                      tab='gallery'
                      projectStore={projectStore!}
                      projectInfos={projectInfos}
                    />
                  )}
                </Route>
                <Route path='/p/:any*'>
                  {() => (
                    <ProjectExplorer
                      tab='private'
                      projectStore={projectStore!}
                      projectInfos={projectInfos}
                    />
                  )}
                </Route>
                <Route path='/'>
                  {() => (
                    <ProjectExplorer
                      tab='private'
                      projectStore={projectStore!}
                      projectInfos={projectInfos}
                    />
                  )}
                </Route>
                <Route path='/:any*'>
                  <br />
                  <br />
                  <Typography component='h1' variant='h2' align='center' color='textSecondary'>
                    404 :(
                  </Typography>
                </Route>
              </Switch>
            )}
          </Suspense>
          {chooseTemplateOpen && <ChooseTemplate onDone={onChooseTemplateDone} />}
        </Div100vh>
      </UserContext.Provider>
    </AppContext.Provider>
  );

  function onChooseTemplateDone(projectInfo?: ProjectInfo, title?: string): void {
    setChooseTemplateOpen(false);

    if (projectInfo) {
      // TODO: my own setLocation that accepts state?
      setLocation(`/e/${projectInfo.id}?fork&title=${encodeURIComponent(title!)}&from=${location}`);
    }
  }
};

export const Portal: React.FC<{ parentElement?: HTMLElement | null }> = (props) => {
  if (!props.parentElement) {
    return null;
  }
  return ReactDOM.createPortal(props.children, props.parentElement);
};
