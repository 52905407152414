import { DBFileSystem } from './utils/dbFileSystem';
import { db, TIMESTAMP } from './firebase';
import { ProjectId, ProjectInfo, ProjectState, ReactorArray, ResourceId, UserId } from './runtime';
import { deserialize, readRemoteProject } from './runtime/importer';
import { User } from './user';
import { generateUUID, hex, suggestName, suggestSuffix } from './utils/util';

export const vizMimeType = 'application/viz';

export const emptyProjectInfo = {
  id: 'empty',
  owner: 'NCDoA4SoNaOG1CuCsCmJ1msJQp33',
  ownerName: 'Viz',
  name: 'empty',
  title: 'Empty Project',
  deck: 'empty',
  modified: 0,
  created: 0,
  template: 'empty',
  sharing: 'public',
  preview: '', // TODO: empty image
  version: 0,
};

export class ProjectStore {
  private remoteProjectInfos: ProjectInfo[];
  private localProjectInfos: ProjectInfo[] = [];
  private collection: string;

  constructor(
    private userId: UserId,
    private _public: boolean,
    private listener: (projectInfos: ProjectInfo[]) => void
  ) {
    if (this._public) {
      this.collection = `publicDecks`;
    } else {
      this.collection = `decks/${this.userId}`;

      // Get the locally stored ProjectInfos.
      this.localProjectInfos = readLocalProjectInfos(this.userId);
    }

    this.remoteProjectInfos = [];

    // Notify of initial state.
    this.notifyListener();

    // Get the remotely stored ProjectInfo and watch for changes.
    // TODO: add an index for publicDecks/owner
    // TODO: manually sort by modified?
    let query =
      this._public && this.userId !== 'public'
        ? db.ref('publicDecks').orderByChild('owner').equalTo(userId)
        : db.ref(this.collection).orderByChild('modified');

    query.on('value', this.onDatabaseProjectInfosChange);
  }

  close() {
    db.ref(this.collection).off('value', this.onDatabaseProjectInfosChange);
  }

  getProjectInfo(userId: UserId, projectId: ProjectId): ProjectInfo | undefined {
    return this.getRemoteProjectInfo(projectId) || this.getLocalProjectInfo(projectId);
  }

  async getProjectInfoAsync(
    userId: UserId,
    projectId: ProjectId
  ): Promise<ProjectInfo | undefined> {
    const projectInfo = this.getProjectInfo(userId, projectId);
    if (projectInfo) {
      return projectInfo;
    }

    if (!projectInfo) {
      if (userId !== 'anonymous') {
        try {
          const dbRef = db.ref(`decks/${userId}/${projectId}`);
          const snapshot = await dbRef.once('value');
          const projectInfo = snapshot.val() as ProjectInfo;
          if (projectInfo) {
            return projectInfo;
          }
        } catch (err) {
          // Users that don't own the project fall here and we check if it's public.
        }
      }

      const dbRef = db.ref(`publicDecks/${projectId}`);
      const snapshot = await dbRef.once('value');
      return snapshot.val() as ProjectInfo;
    }
  }

  async readProject(info: ProjectInfo): Promise<ProjectState> {
    const id = info.id;
    const localInfo = this.getLocalProjectInfo(id);
    const remoteInfo = this.getRemoteProjectInfo(id);

    // If the remote version is newer than the local version download it.
    if (localInfo && (!remoteInfo || localInfo.version >= remoteInfo.version)) {
      return readLocalProject(this.userId, id);
    } else {
      let state = await readRemoteProject(info.deck);

      // Upgrade the project to the latest format.
      state = await this.migrateProject(state);

      /* TODO: let's try only saving locally once edited
      // Save Project locally now that we've downloaded it.
      await this.writeLocalProject(projectInfo, state);
      */
      return state;
    }
  }

  async writeProject(projectInfo: ProjectInfo, state: ProjectState): Promise<ProjectInfo> {
    await this.writeLocalProject(projectInfo, state);
    return await this.writeRemoteProject(projectInfo, state);
  }

  async deleteProject(id: ProjectId): Promise<void> {
    const localInfo = this.getLocalProjectInfo(id);
    const remoteInfo = this.getRemoteProjectInfo(id);
    if (localInfo) {
      // Remove the locally stored ProjectInfo.
      const localInfos = this.localProjectInfos.filter((localInfo) => localInfo.id !== id);
      this.writeLocalProjectInfos(this.userId, localInfos);

      // Delete the locally stored Project.
      DBFileSystem.delete(`deck/${this.userId}/${id}`);

      // If it is only local notify listener of the info change. If remote the listener
      // will be notified when the onDatabaseProjectInfosChange notification comes in.
      if (!remoteInfo) {
        this.notifyListener();
      }
    }

    if (remoteInfo) {
      // Delete remotely stored ProjectInfo.
      const dbRef = db.ref(`decks/${remoteInfo.owner}/${remoteInfo.id}`);
      await dbRef.remove();

      // If sharing delete the public ProjectInfo.
      if (remoteInfo.sharing === 'public') {
        const dbRef = db.ref(`publicDecks/${remoteInfo.id}`);
        await dbRef.remove();
      }
    }

    return Promise.resolve();
  }

  async duplicateProject(info: ProjectInfo, newOwner: UserId, newOwnerName: string): Promise<void> {
    const dupInfo = this.duplicateProjectInfo(info, newOwner, newOwnerName);
    const state = await this.readProject(info);
    await this.writeRemoteProjectInfo(dupInfo);
    return this.writeLocalProject(dupInfo, state);
  }

  duplicateProjectInfo(
    info: ProjectInfo,
    owner: UserId,
    ownerName: string,
    title?: string,
    template = false,
    version: string | undefined = undefined
  ): ProjectInfo {
    if (template) {
      title = suggestTitle(title!, info.title, this.mergedInfos);
    } else if (version) {
      // TODO: better new title
      title = info.title + ' ' + version.slice(0, 4);
    } else {
      title = info.title + ' copy';
    }

    const dupInfo: ProjectInfo = {
      id: generateUUID(),
      owner,
      ownerName,
      name: template
        ? suggestName(
            info.name,
            this.mergedInfos.map((info) => info.name)
          )
        : info.name,
      title,
      template: template ? info.id : info.template,
      preview: info.preview,
      deck: info.deck,
      version: 0,
      // TODO: If version specified, use its publishedDate for created.
      created: TIMESTAMP as number,
      modified: TIMESTAMP as number,
      sharing: 'private',
    };

    if (info.tags) {
      dupInfo.tags = { ...info.tags };
    }

    return dupInfo;
  }

  newProjectInfo(user: User, name: string, title: string): ProjectInfo {
    return {
      id: generateUUID(),
      owner: user.id,
      ownerName: user.name,
      name: suggestName(
        name,
        this.mergedInfos.map((info) => info.name)
      ),
      title: suggestTitle(title!, title, this.mergedInfos),
      template: '',
      preview: '',
      deck: 'placeholder',
      version: 0,
      created: TIMESTAMP as number,
      modified: TIMESTAMP as number,
      sharing: 'private',
    };
  }

  // TODO: Move publishing to the backend. Can't trust clients to write ProjectInfo.
  async publish(id: ProjectId): Promise<void> {
    const info = this.getRemoteProjectInfo(id);
    if (!info) {
      // TODO: enforce can only publish remotely saved projects
      return;
    }

    info.publishedVersion = info.version;
    if (!info.publishedHistory) {
      info.publishedHistory = [];
    }
    info.publishedHistory.push(info.deck);
    info.publishedDeck = info.deck;
    info.published = TIMESTAMP as number;

    return this.writeProjectInfo(info);
  }

  async unpublish(id: ProjectId): Promise<void> {
    const info = this.getRemoteProjectInfo(id);
    if (!info) {
      // TODO: enforce can only unpublish remotely saved projects
      return;
    }

    delete info.publishedDeck;
    delete info.publishedVersion;
    delete info.published;
    delete info.publishedHistory;

    return this.writeProjectInfo(info);
  }

  async writeProjectInfo(info: ProjectInfo): Promise<void> {
    if (this.getLocalProjectInfo(info.id)) {
      await this.writeLocalProjectInfo(info);
    }
    if (this.getRemoteProjectInfo(info.id)) {
      await this.writeRemoteProjectInfo(info);
    }
  }

  async writeLocalProjectInfo(info: ProjectInfo): Promise<void> {
    const infos = this.localProjectInfos.filter((localInfo) => localInfo.id !== info.id);

    // Normally the server provides the timestamp because we can't trust clients' clocks.
    // But when we save info locally local time is all we have.
    const timeStampedInfo = { ...info };
    if (info.created === TIMESTAMP) {
      timeStampedInfo.created = Date.now();
    }
    if (info.modified === TIMESTAMP) {
      timeStampedInfo.modified = Date.now();
    }
    if (info.published === TIMESTAMP) {
      timeStampedInfo.published = Date.now();
    }
    infos.unshift(timeStampedInfo);

    this.writeLocalProjectInfos(info.owner, infos);
    this.notifyListener();
  }

  async writeRemoteProjectInfo(info: ProjectInfo): Promise<ProjectInfo> {
    if (this.userId === 'anonymous') {
      return { ...info };
    }

    let dbRef = db.ref(`decks/${this.userId}/${info.id}`);

    // If this is a new project use 'set' otherwise 'update' to avoid overwriting
    // the created timestamp.
    let writePromise;
    if (info.created === TIMESTAMP) {
      writePromise = dbRef.set(info);
    } else {
      // TODO: published?
      delete info.created;
      if (info.modified !== TIMESTAMP) {
        delete info.modified;
      }
      writePromise = dbRef.update(info);
    }

    // Read back the server-defined timestamp values.
    const snapshot = await dbRef.once('value');
    info = snapshot.val() as ProjectInfo;

    await writePromise;

    // If sharing write to the public project list.
    if (info.sharing === 'public') {
      // TODO: Prune info that shouldn't be public.
      const publicInfo = { ...info };
      delete publicInfo.publishedHistory;

      dbRef = db.ref(`publicDecks/${info.id}`);
      await dbRef.set(publicInfo);
    }
    return info;
  }

  async getResourceId(resource: ArrayBuffer): Promise<ResourceId> {
    const hash = await crypto.subtle.digest('SHA-256', resource);
    return hex(hash);
  }

  async addResource(resource: ArrayBuffer, mimeType?: string): Promise<ResourceId> {
    const resid = await this.getResourceId(resource);

    await fetch(`/backend/resource/${resid}`, {
      method: 'PUT',
      body: resource,
      headers: { 'Content-Type': mimeType ?? 'application/octet-stream' },
    });

    return resid;
  }

  // TODO: Test Firebase rules to ensure that only the project's owner can change its sharing.
  async setProjectSharing(info: ProjectInfo, sharing: string): Promise<void> {
    if (info.sharing === sharing) {
      return;
    }

    info.sharing = sharing;
    if (sharing === 'private') {
      const dbRef = db.ref(`publicDecks/${info.id}`);
      await dbRef.remove();
    }
    await this.writeProjectInfo(info);
  }

  private async migrateProject(state: ProjectState): Promise<ProjectState> {
    return state;
  }

  private writeLocalProjectInfos(userId: UserId, projectInfos: ProjectInfo[]): void {
    const json = JSON.stringify(projectInfos, null, 2);
    localStorage[`deckInfos/${userId}`] = json;
    this.localProjectInfos = projectInfos;
  }

  private getLocalProjectInfo(id: ProjectId): ProjectInfo | undefined {
    const info = this.localProjectInfos.find((info) => info.id === id);
    return info && { ...info };
  }

  private getRemoteProjectInfo(id: ProjectId): ProjectInfo | undefined {
    const info = this.remoteProjectInfos.find((info) => info.id === id);
    return info && { ...info };
  }

  // TODO: progress
  private async writeRemoteProject(
    projectInfo: ProjectInfo,
    state: ProjectState
  ): Promise<ProjectInfo> {
    // Write the project's files to remote file storage.
    const file = serialize({ state });

    const array = new TextEncoder().encode(file);
    projectInfo.deck = await this.addResource(array.buffer, 'application/json');

    // Write the deck's info to the database.
    return this.writeRemoteProjectInfo(projectInfo);
  }

  // TODO: not going to receive ALL values EVERY time!
  // TODO: on add, remove, etc
  private onDatabaseProjectInfosChange = (snapshot: firebase.database.DataSnapshot): void => {
    const infos = snapshot.val() ?? {};
    this.remoteProjectInfos = Object.values(infos);

    //console.log('onDatabaseProjectInfosChange:', this.remoteProjectInfos);
    this.notifyListener();
  };

  private notifyListener(): void {
    this.listener(this.mergedInfos);
  }

  private get mergedInfos(): ProjectInfo[] {
    return this.mergeInfos(this.localProjectInfos, this.remoteProjectInfos);
  }

  private async writeLocalProject(projectInfo: ProjectInfo, state: ProjectState): Promise<void> {
    const json = serialize(state);
    const dbfs = await DBFileSystem.open(`deck/${this.userId}/${projectInfo.id}`);
    await dbfs.writeFile('deck.deck', json);
    await dbfs.dispose();

    return this.writeLocalProjectInfo(projectInfo);
  }

  private mergeInfos(localInfos: ProjectInfo[], remoteInfos: ProjectInfo[]): ProjectInfo[] {
    const mergedInfos = [];
    for (const remoteInfo of remoteInfos) {
      const localInfo = localInfos.find((localInfo) => localInfo.id === remoteInfo.id);
      if (localInfo) {
        // If we have local and remote info for a project keep whichever is the newest.
        if (remoteInfo.version > localInfo.version || remoteInfo.modified >= localInfo.modified) {
          mergedInfos.push({ ...remoteInfo });
        } else {
          mergedInfos.push({ ...localInfo });
          // TODO: update the remote database? Would mean uploading the whole project.
        }
      } else {
        mergedInfos.push({ ...remoteInfo });
      }
    }

    // Add local projects that aren't known by the remote database.
    // TODO: add them to the remote database?  Would mean uploading the whole project.
    for (const localInfo of localInfos) {
      if (!mergedInfos.find((mergedInfo) => mergedInfo.id === localInfo.id)) {
        mergedInfos.push({ ...localInfo });
      }
    }

    // Upgrade infos.
    mergedInfos.forEach((info) => (info.tags = info.tags ?? {}));

    return mergedInfos;
  }
}

function readLocalProjectInfos(userId: UserId): ProjectInfo[] {
  const json = localStorage[`deckInfos/${userId}`] as string;
  if (!json) return [];

  let projectInfos = JSON.parse(json) as ProjectInfo[];

  // Return only the ProjectInfos owned by this user.
  return projectInfos.filter((info) => info.owner === userId);
}

// TODO: progress
async function readLocalProject(userId: UserId, projectId: ProjectId): Promise<ProjectState> {
  const dbfs = await DBFileSystem.open(`deck/${userId}/${projectId}`);
  const fsdata = await dbfs.readFile('deck.deck');
  const jsonText = fsdata.string!;
  dbfs.dispose();

  return deserialize(jsonText) as ProjectState;
}

export function getProjectInfosByTag(tag: string): Promise<ProjectInfo[]> {
  return new Promise(async (resolve, reject) => {
    const snapshot = await db
      .ref('publicDecks')
      .orderByChild(`tags/${tag}`)
      .equalTo(true)
      .once('value');

    const infosObj = snapshot.val();
    if (infosObj) {
      resolve(Object.values(infosObj));
    } else {
      resolve(undefined);
    }
  });
}

function suggestTitle(title: string, template: string, projectInfos: ProjectInfo[]): string {
  const suffix = suggestSuffix(
    template,
    projectInfos.map((info) => info.title),
    false,
    true
  );
  return title + (suffix ? ' ' + suffix : '');
}

// TODO: move to reactor.ts?
// TODO: dates, regex
export function serialize(state: any): string {
  return JSON.stringify(
    state,
    (_key: string, value: any): any => {
      // ReactorArray ids are encoded as an extra element at the end of the array.
      if (Array.isArray(value) && (value as any).id) {
        const valuePlusId = value.slice();
        valuePlusId.push(`__id:${(value as ReactorArray).id}`);
        return valuePlusId;
      } else {
        return value;
      }
    },
    2
  );
}
