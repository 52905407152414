import * as paths from './paths';
import {
  FileOperation,
  BaseAsyncFileSystem,
  DirectoryEntry,
  FSData,
  Stat,
  FSHelpers,
  FileUpdate,
  WalkInfo,
} from './fileSystem';

// AsyncFileSystem implementation on top of IndexedDB.
// Note can't use native Promises within a single IndexedDB transaction lifetime
// compatibly across browsers, so use callbacks.

type Entry = {
  path: string;
  dir: string;
  idData: number;
};

type DBCallback = (error?: Error, data?: any) => void;

export class DBFileSystem extends BaseAsyncFileSystem {
  private static osEntriesName = 'entries';
  private static osDataName = 'data';
  private static dbNamePrefix = 'dbfs-';

  public static open(name: string): Promise<DBFileSystem> {
    return new Promise<DBFileSystem>((resolve, reject) => {
      // eslint-disable-next-line no-restricted-globals
      const req = self.indexedDB.open(DBFileSystem.dbNamePrefix + name, 1);
      req.onupgradeneeded = () => {
        // Two object stores:
        // 1. Entries object store { (primary key) path, (indexed) dir, idData }
        // 2. Data object store { (primary key) id, data }
        const db: IDBDatabase = req.result;
        const osEntries = db.createObjectStore(DBFileSystem.osEntriesName, { keyPath: 'path' });
        osEntries.createIndex('dir', 'dir', { unique: false });
        const osData = db.createObjectStore(DBFileSystem.osDataName, {
          keyPath: 'id',
          autoIncrement: true,
        });

        let entriesComplete = false;
        let dataComplete = false;

        osEntries.transaction.oncomplete = () => {
          entriesComplete = true;
          if (dataComplete) {
            resolve(new DBFileSystem(db));
          }
        };

        osEntries.transaction.onerror = () => {
          reject(DBFileSystem.errorFromException(osEntries.transaction.error));
        };

        osData.transaction.oncomplete = () => {
          dataComplete = true;
          if (entriesComplete) {
            resolve(new DBFileSystem(db));
          }
        };

        osData.transaction.onerror = () => {
          reject(DBFileSystem.errorFromException(osData.transaction.error));
        };
      };

      req.onsuccess = () => {
        resolve(new DBFileSystem(req.result));
      };

      req.onerror = () => {
        reject(DBFileSystem.errorFromException(req.error!));
      };
    });
  }

  public static delete(name: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // eslint-disable-next-line no-restricted-globals
      const req = self.indexedDB.deleteDatabase(DBFileSystem.dbNamePrefix + name);
      req.onerror = () => resolve();
      req.onsuccess = () => resolve();
      req.onblocked = () => {
        // NOTE: The delete request still tries to complete and ultimately will if/when
        // the database is closed.
        reject(new Error(`database '${name}' deletion blocked`));
      };
    });
  }

  private static errorFromException(ex: DOMException): Error {
    const error = new Error(ex.message);
    error.name = ex.name;
    return error;
  }

  private constructor(private db: IDBDatabase) {
    super();
  }

  public stat(path: string): Promise<Stat> {
    return new Promise<Stat>((resolve, reject) => {
      // Create a transaction.
      const trans = this.db.transaction(DBFileSystem.osEntriesName, 'readonly');
      trans.onerror = () => reject(DBFileSystem.errorFromException(trans.error));

      // Perform the stat in this transaction.
      this.statInTransaction(path, trans, (error: Error | undefined, stat: Stat) => {
        if (error) {
          reject(error);
        } else {
          resolve(stat ?? null);
        }
      });
    });
  }

  private statInTransaction(path: string, trans: IDBTransaction, callback: DBCallback): void {
    path = FSHelpers.normalizePath(path);
    if (path === '') {
      callback(undefined, { isDir: true });
      return;
    }
    const osEntries = trans.objectStore(DBFileSystem.osEntriesName);
    const reqReadEntry = osEntries.get(path);
    reqReadEntry.onerror = () => callback(DBFileSystem.errorFromException(reqReadEntry.error!));
    reqReadEntry.onsuccess = () => {
      const entry: Entry = reqReadEntry.result;
      if (entry) {
        callback(undefined, { isDir: !entry.idData });
      } else {
        // File or directory does not exist
        callback();
      }
    };
  }

  public readFile(path: string): Promise<FSData> {
    return new Promise<FSData>((resolve, reject) => {
      // Create a transaction. oncomplete handler not required.
      const trans = this.db.transaction(
        [DBFileSystem.osEntriesName, DBFileSystem.osDataName],
        'readonly'
      );
      trans.onerror = () => reject(DBFileSystem.errorFromException(trans.error));
      trans.onabort = () => reject(DBFileSystem.errorFromException(trans.error));

      // Read the file in this transaction.
      this.readFileInTransaction(path, trans, (error: Error | undefined, data: FSData) => {
        if (error) {
          reject(error);
        } else {
          resolve(data ?? null);
        }
      });
    });
  }

  private readFileInTransaction(path: string, trans: IDBTransaction, callback: DBCallback): void {
    path = FSHelpers.normalizePath(path);
    const osEntries = trans.objectStore(DBFileSystem.osEntriesName);
    const reqReadEntry = osEntries.get(path);
    reqReadEntry.onerror = () => callback(DBFileSystem.errorFromException(reqReadEntry.error!));
    reqReadEntry.onsuccess = () => {
      const entry: Entry = reqReadEntry.result;
      if (entry?.idData) {
        const osData = trans.objectStore(DBFileSystem.osDataName);
        const reqReadData = osData.get(entry.idData);
        reqReadData.onerror = () => callback(DBFileSystem.errorFromException(reqReadData.error!));
        reqReadData.onsuccess = () => {
          if (reqReadData?.result) {
            callback(undefined, new FSData(reqReadData.result.data));
          } else {
            callback();
          }
        };
      } else {
        callback();
      }
    };
  }

  public writeFile(path: string, data: string | ArrayBuffer | FSData): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // Create a transaction.
      const trans = this.db.transaction(
        [DBFileSystem.osEntriesName, DBFileSystem.osDataName],
        'readwrite'
      );
      trans.onerror = () => reject(DBFileSystem.errorFromException(trans.error));
      trans.onabort = () => reject(DBFileSystem.errorFromException(trans.error));

      // Write the file in this transaction.
      const updates: FileUpdate[] = [];
      this.writeFileInTransaction(
        path,
        data,
        trans,
        updates,
        (error: Error | undefined, success: boolean) => {
          if (error) {
            reject(error);
            if (!trans.error) {
              trans.abort();
            }
          } else {
            this.notify(updates).then(() => resolve(success ?? false));
          }
        }
      );
    });
  }

  private writeFileInTransaction(
    path: string,
    data: string | ArrayBuffer | FSData,
    trans: IDBTransaction,
    updates: FileUpdate[],
    callback: DBCallback
  ): void {
    path = FSHelpers.normalizePath(path);
    const dirPath = paths.dirname(path);

    // Get the data to write.
    let dataWrite: string | ArrayBuffer;
    if (typeof data === 'string' || data instanceof ArrayBuffer) {
      dataWrite = data;
    } else {
      dataWrite = (data as FSData).data;
    }

    // See if the file is there already.
    const osEntries = trans.objectStore(DBFileSystem.osEntriesName);
    const reqReadEntry = osEntries.get(path);
    reqReadEntry.onerror = () => callback(DBFileSystem.errorFromException(reqReadEntry.error!));
    reqReadEntry.onsuccess = () => {
      // Read successful. File exists?
      const entry: Entry = reqReadEntry.result;
      if (!entry) {
        // File doesn't exist... create the path, then write the file
        this.mkdirInTransaction(
          dirPath,
          trans,
          updates,
          (error: Error | undefined, success: boolean) => {
            if (error) {
              callback(error);
            } else if (!success) {
              callback(undefined, false);
            } else {
              // Write the data first, then the entry.
              const osData = trans.objectStore(DBFileSystem.osDataName);
              const reqWriteData = osData.put({ data: dataWrite });
              reqWriteData.onerror = () =>
                callback(DBFileSystem.errorFromException(reqWriteData.error!));
              reqWriteData.onsuccess = () => {
                const reqWriteEntry = osEntries.put({
                  path,
                  dir: dirPath,
                  idData: reqWriteData.result,
                });
                reqWriteEntry.onerror = () =>
                  callback(DBFileSystem.errorFromException(reqWriteEntry.error!));
                reqWriteEntry.onsuccess = () => {
                  updates.push({ op: FileOperation.CREATE, path });
                  callback(undefined, true);
                };
              };
            }
          }
        );
      } else {
        // Write the data if entry isn't a directory.
        if (!entry.idData) {
          // Trying to write file on top of a directory
          callback(undefined, false);
        } else {
          const osData = trans.objectStore(DBFileSystem.osDataName);
          const reqWriteData = osData.put({ id: entry.idData, data: dataWrite });
          reqWriteData.onerror = () =>
            callback(DBFileSystem.errorFromException(reqWriteData.error!));
          reqWriteData.onsuccess = () => {
            updates.push({ op: FileOperation.UPDATE, path });
            callback(undefined, true);
          };
        }
      }
    };
  }

  public removeFile(path: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // Create a transaction.
      const trans = this.db.transaction(
        [DBFileSystem.osEntriesName, DBFileSystem.osDataName],
        'readwrite'
      );
      trans.onerror = () => reject(DBFileSystem.errorFromException(trans.error));
      trans.onabort = () => reject(DBFileSystem.errorFromException(trans.error));

      // Perform the remove in this transaction.
      const updates: FileUpdate[] = [];
      this.removeFileInTransaction(
        path,
        trans,
        updates,
        (error: Error | undefined, success: boolean) => {
          if (error) {
            reject(error);
            if (!trans.error) {
              trans.abort();
            }
          } else {
            this.notify(updates).then(() => resolve(success ?? false));
          }
        }
      );
    });
  }

  private removeFileInTransaction(
    path: string,
    trans: IDBTransaction,
    updates: FileUpdate[],
    callback: DBCallback
  ): void {
    // Read first to see if file exists.
    path = FSHelpers.normalizePath(path);
    const osEntries = trans.objectStore(DBFileSystem.osEntriesName);
    const reqReadEntry = osEntries.get(path);
    reqReadEntry.onerror = () => callback(DBFileSystem.errorFromException(reqReadEntry.error!));
    reqReadEntry.onsuccess = () => {
      // Don't delete if doesn't exist or if directory
      const entry: Entry = reqReadEntry.result;
      if (!entry) {
        callback(undefined, true);
      }
      if (!entry.idData) {
        callback(undefined, false);
      } else {
        const osData = trans.objectStore(DBFileSystem.osDataName);
        const reqDelData = osData.delete(entry.idData);
        reqDelData.onerror = () => callback(DBFileSystem.errorFromException(reqDelData.error!));
        reqDelData.onsuccess = () => {
          const reqDelEntry = osEntries.delete(path);
          reqDelEntry.onerror = () => callback(DBFileSystem.errorFromException(reqDelEntry.error!));
          reqDelEntry.onsuccess = () => {
            updates.push({ op: FileOperation.DELETE, path });
            callback(undefined, true);
          };
        };
      }
    };
  }

  public readdir(path: string): Promise<DirectoryEntry[]> {
    return new Promise<DirectoryEntry[]>((resolve, reject) => {
      // Create a transaction.
      const trans = this.db.transaction(DBFileSystem.osEntriesName, 'readonly');
      trans.onerror = reject;
      trans.onabort = reject;

      // Perform the readdir in this transaction.
      this.readdirInTransaction(
        path,
        trans,
        (error: Error | undefined, entries: DirectoryEntry[]) => {
          if (error) {
            reject(error);
          } else {
            resolve(entries ?? undefined);
          }
        }
      );
    });
  }

  private readdirInTransaction(path: string, trans: IDBTransaction, callback: DBCallback): void {
    const osEntries = trans.objectStore(DBFileSystem.osEntriesName);
    const index = osEntries.index('dir');
    const range = IDBKeyRange.only(FSHelpers.normalizePath(path));
    const reqCursor = index.openCursor(range);
    let results: { name: string; stat: Stat }[] | null = null;
    reqCursor.onerror = () => callback(DBFileSystem.errorFromException(reqCursor.error!));
    reqCursor.onsuccess = () => {
      const cursor = reqCursor.result;
      if (cursor) {
        if (!results) {
          results = [];
        }
        results.push({
          name: paths.basename(cursor.value.path),
          stat: { isDir: !cursor.value.idData },
        });
        cursor.continue();
      } else {
        if (results) {
          callback(undefined, results);
        } else {
          // No files found in this directory. The directory either doesn't exist or has no entries.
          this.statInTransaction(path, trans, (error, stat) => {
            if (error) {
              callback(error);
            } else if (!stat || !stat.isDir) {
              callback();
            } else {
              callback(undefined, []);
            }
          });
        }
      }
    };
  }

  public mkdir(path: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // Create a transaction.
      const trans = this.db.transaction(DBFileSystem.osEntriesName, 'readwrite');
      trans.onerror = () => reject(DBFileSystem.errorFromException(trans.error));
      trans.onabort = () => reject(DBFileSystem.errorFromException(trans.error));

      // Perform the mkdir in this transaction.
      const updates: FileUpdate[] = [];
      this.mkdirInTransaction(
        path,
        trans,
        updates,
        (error: Error | undefined, success: boolean) => {
          if (error) {
            reject(error);
            if (!trans.error) {
              trans.abort();
            }
          } else {
            this.notify(updates).then(() => resolve(success ?? false));
          }
        }
      );
    });
  }

  private mkdirInTransaction(
    path: string,
    trans: IDBTransaction,
    updates: FileUpdate[],
    callback: DBCallback
  ): void {
    const osEntries = trans.objectStore(DBFileSystem.osEntriesName);

    const directoryExists = (path: string, callback: DBCallback): void => {
      const reqReadEntry = osEntries.get(path);
      reqReadEntry.onerror = () => callback(DBFileSystem.errorFromException(reqReadEntry.error!));
      reqReadEntry.onsuccess = () => {
        // Read successful. Directory exists?
        const entry: Entry = reqReadEntry.result;
        if (!entry) {
          callback(undefined, false);
        } else {
          // If it's not a directory, treat as error
          if (entry.idData) {
            // Cannot create directory over an existing file
            callback(undefined, false);
          }
          callback(undefined, true);
        }
      };
    };

    const newDirectory = (path: string, callback: DBCallback): void => {
      // Directory doesn't exist... create it
      const reqWriteEntry = osEntries.put({ path, dir: paths.dirname(path), idData: 0 });
      reqWriteEntry.onerror = () => callback(DBFileSystem.errorFromException(reqWriteEntry.error!));
      reqWriteEntry.onsuccess = () => {
        updates.push({ op: FileOperation.CREATE, path });
        callback();
      };
    };

    // The loop creates dirs in reverse order (longest to shortest),
    // this way the desired path is checked first. Ok to make directory in reverse order.
    if (path === '') {
      callback(undefined, true);
    } else {
      const parts = FSHelpers.getPathParts(path);
      const iterate = () => {
        if (parts.length === 0) {
          callback(undefined, true);
        } else {
          const subPath = parts.join('/');
          directoryExists(subPath, (error: Error | undefined, exists: boolean) => {
            if (error) {
              callback(error);
            } else if (exists) {
              callback(undefined, true);
            } else {
              newDirectory(subPath, (error: Error | undefined) => {
                if (error) {
                  callback(error);
                } else {
                  parts.pop();
                  iterate();
                }
              });
            }
          });
        }
      };
      iterate();
    }
  }

  public rmdir(path: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // Create a transaction.
      const trans = this.db.transaction(
        [DBFileSystem.osEntriesName, DBFileSystem.osDataName],
        'readwrite'
      );
      trans.onerror = () => reject(DBFileSystem.errorFromException(trans.error));
      trans.onabort = () => reject(DBFileSystem.errorFromException(trans.error));

      // Perform the rmdir in this transaction.
      const updates: FileUpdate[] = [];
      this.rmdirInTransaction(
        path,
        trans,
        updates,
        (error: Error | undefined, success: boolean) => {
          if (error) {
            reject(error);
            if (!trans.error) {
              trans.abort();
            }
          } else {
            this.notify(updates).then(() => resolve(success ?? false));
          }
        }
      );
    });
  }

  private rmdirInTransaction(
    path: string,
    trans: IDBTransaction,
    updates: FileUpdate[],
    callback: DBCallback
  ): void {
    const removeDirEntry = (path: string, callback: DBCallback): void => {
      const osEntries = trans.objectStore(DBFileSystem.osEntriesName);
      const reqDelEntry = osEntries.delete(path);
      reqDelEntry.onerror = () => callback(DBFileSystem.errorFromException(reqDelEntry.error!));
      reqDelEntry.onsuccess = () => callback();
    };

    path = FSHelpers.normalizePath(path);
    this.readdirInTransaction(
      path,
      trans,
      (error: Error | undefined, entries: DirectoryEntry[]) => {
        if (error) {
          callback(error);
        } else {
          entries = entries ?? [];
          const iterate = () => {
            if (entries.length === 0) {
              removeDirEntry(path, (error) => {
                if (error) {
                  callback(error);
                } else {
                  updates.push({ op: FileOperation.DELETE, path });
                  callback(undefined, true);
                }
              });
            } else {
              const entry = entries.shift()!;
              const filePath = paths.join(path, entry.name);
              if (entry.stat.isDir) {
                this.rmdirInTransaction(filePath, trans, updates, (error) => {
                  if (error) {
                    callback(error);
                  } else {
                    iterate();
                  }
                });
              } else {
                this.removeFileInTransaction(filePath, trans, updates, (error) => {
                  if (error) {
                    callback(error);
                  } else {
                    iterate();
                  }
                });
              }
            }
          };
          iterate();
        }
      }
    );
  }

  public rename(srcPath: string, dstPath: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // Create a transaction.
      const trans = this.db.transaction(
        [DBFileSystem.osEntriesName, DBFileSystem.osDataName],
        'readwrite'
      );
      trans.onerror = () => reject(DBFileSystem.errorFromException(trans.error));
      trans.onabort = () => reject(DBFileSystem.errorFromException(trans.error));

      // Perform the rename in this transaction.
      const updates: FileUpdate[] = [];
      this.renameInTransaction(
        srcPath,
        dstPath,
        trans,
        updates,
        (error: Error | undefined, success: boolean) => {
          if (error) {
            reject(error);
            if (!trans.error) {
              trans.abort();
            }
          } else {
            this.notify(updates).then(() => resolve(success ?? false));
          }
        }
      );
    });
  }

  // Follows the behavior of http://man7.org/linux/man-pages/man2/rename.2.html
  // 1. Can rename a file or directory in place.
  // 2. Can move a file to a new path.
  // 3. Can move a file over (replace) an existing file
  // 4. Can move a directory to new path if the new path does not exist or is an empty directory.
  private renameInTransaction(
    srcPath: string,
    dstPath: string,
    trans: IDBTransaction,
    updates: FileUpdate[],
    callback: DBCallback
  ): void {
    const osEntries = trans.objectStore(DBFileSystem.osEntriesName);
    const osData = trans.objectStore(DBFileSystem.osDataName);

    const renameDirEntry = (srcPath: string, dstPath: string, callback: DBCallback): void => {
      const reqWriteDstEntry = osEntries.put({
        path: dstPath,
        dir: paths.dirname(dstPath),
        idData: 0,
      });
      reqWriteDstEntry.onerror = () =>
        callback(DBFileSystem.errorFromException(reqWriteDstEntry.error!));
      reqWriteDstEntry.onsuccess = () => {
        const reqDelSrcEntry = osEntries.delete(srcPath);
        reqDelSrcEntry.onerror = () =>
          callback(DBFileSystem.errorFromException(reqDelSrcEntry.error!));
        reqDelSrcEntry.onsuccess = () => {
          callback();
        };
      };
    };

    const renameFileEntry = (srcPath: string, dstPath: string, callback: DBCallback): void => {
      const reqReadSrcEntry = osEntries.get(srcPath);
      reqReadSrcEntry.onerror = () =>
        callback(DBFileSystem.errorFromException(reqReadSrcEntry.error!));
      reqReadSrcEntry.onsuccess = () => {
        const srcEntry: Entry = reqReadSrcEntry.result;
        if (!srcEntry) {
          callback(undefined, false);
        } else {
          const reqWriteDstEntry = osEntries.put({
            path: dstPath,
            dir: paths.dirname(dstPath),
            idData: srcEntry.idData,
          });
          reqWriteDstEntry.onerror = () =>
            callback(DBFileSystem.errorFromException(reqWriteDstEntry.error!));
          reqWriteDstEntry.onsuccess = () => {
            const reqDelSrcEntry = osEntries.delete(srcPath);
            reqDelSrcEntry.onerror = () =>
              callback(DBFileSystem.errorFromException(reqDelSrcEntry.error!));
            reqDelSrcEntry.onsuccess = () => {
              callback(undefined, true);
            };
          };
        }
      };
    };

    const renameFile = (
      srcPath: string,
      dstPath: string,
      dstStat: Stat,
      callback: DBCallback
    ): void => {
      if (dstStat) {
        const reqReadDstEntry = osEntries.get(dstPath);
        reqReadDstEntry.onsuccess = () => {
          const dstEntry: Entry = reqReadDstEntry.result;
          if (!dstEntry) {
            callback(undefined, false);
          } else {
            const reqDelDstData = osData.get(dstEntry.idData);
            reqDelDstData.onerror = () =>
              callback(DBFileSystem.errorFromException(reqDelDstData.error!));
            reqDelDstData.onsuccess = () => {
              renameFileEntry(srcPath, dstPath, callback);
            };
          }
        };
      } else {
        renameFileEntry(srcPath, dstPath, callback);
      }
    };

    const renameDir = (
      srcPath: string,
      dstPath: string,
      dstStat: Stat,
      callback: DBCallback
    ): void => {
      this.readdirInTransaction(
        srcPath,
        trans,
        (error: Error | undefined, entries: DirectoryEntry[]) => {
          if (error) {
            callback(error);
          } else if (!entries) {
            // Directory does not exist
            callback(undefined, false);
          } else {
            renameDirEntry(srcPath, dstPath, (error) => {
              if (error) {
                callback(error);
              } else {
                const iterate = () => {
                  if (entries.length === 0) {
                    callback(undefined, true);
                  } else {
                    const entry = entries.shift()!;
                    if (entry.stat.isDir) {
                      renameDir(
                        paths.join(srcPath, entry.name),
                        paths.join(dstPath, entry.name),
                        dstStat,
                        (error, success) => {
                          if (error) {
                            callback(error);
                          } else if (!success) {
                            callback(undefined, false);
                          } else {
                            iterate();
                          }
                        }
                      );
                    } else {
                      renameFile(
                        paths.join(srcPath, entry.name),
                        paths.join(dstPath, entry.name),
                        dstStat,
                        (error, success) => {
                          if (error) {
                            callback(error);
                          } else if (!success) {
                            callback(undefined, false);
                          } else {
                            iterate();
                          }
                        }
                      );
                    }
                  }
                };
                iterate();
              }
            });
          }
        }
      );
    };

    srcPath = FSHelpers.normalizePath(srcPath);
    dstPath = FSHelpers.normalizePath(dstPath);

    const prepStats = (callback: DBCallback) => {
      // Get the stat of the source.
      this.statInTransaction(srcPath, trans, (error: Error | undefined, srcStat: Stat) => {
        // Src should exist
        if (error) {
          callback(error);
        }
        if (!srcStat) {
          // Source does not exist
          callback();
        } else {
          // Ok for the dst not to exist
          this.statInTransaction(dstPath, trans, (_error: Error | undefined, dstStat: Stat) => {
            // Make sure it's a legal combo
            if (srcPath === dstPath) {
              // Path equal
              callback();
            } else if (dstStat) {
              // Ensure file -> file or dir -> dir.
              if (srcStat.isDir !== dstStat.isDir) {
                // Renaming file to dir or vice versa
                callback();
              } else {
                // If dir, ensure the dst is empty. This ensures the dst is
                // not already in the src.
                if (dstStat.isDir) {
                  this.readdirInTransaction(
                    dstPath,
                    trans,
                    (error: Error | undefined, entries: DirectoryEntry[]) => {
                      if (error) {
                        callback(error);
                      } else if (!entries) {
                        // Dest does not exist
                        callback();
                      } else {
                        if (entries.length !== 0) {
                          // Dest dir is not empty
                          callback();
                        } else {
                          // This dst is about to be clobbered so notify of that
                          updates.push({ op: FileOperation.DELETE, path: dstPath });
                          callback(undefined, { srcStat, dstStat });
                        }
                      }
                    }
                  );
                } else {
                  // This dst is about to be clobbered so notify of that
                  updates.push({ op: FileOperation.DELETE, path: dstPath });
                  callback(undefined, { srcStat, dstStat });
                }
              }
            } else {
              // Dst doesn't exist. Ensure the parent exists.
              this.statInTransaction(paths.dirname(dstPath), trans, (error, parentStat) => {
                if (error) {
                  callback(error);
                } else if (!parentStat) {
                  // Invalid destination path
                  callback();
                } else {
                  callback(undefined, { srcStat, dstStat });
                }
              });
            }
          });
        }
      });
    };

    prepStats((error: Error | undefined, stats: { srcStat: Stat; dstStat: Stat }) => {
      if (error) {
        callback(error);
      } else if (!stats) {
        callback(undefined, false);
      } else {
        if (stats.srcStat.isDir) {
          renameDir(srcPath, dstPath, stats.dstStat, (error, success) => {
            if (error) {
              callback(error);
            } else if (!success) {
              callback(undefined, false);
            } else {
              // Notify for the original rename request.
              updates.push({ op: FileOperation.RENAME, path: dstPath, oldPath: srcPath });
              callback(undefined, true);
            }
          });
        } else {
          renameFile(srcPath, dstPath, stats.dstStat, (error) => {
            if (error) {
              callback(error);
            } else {
              // Notify for the original rename request.
              updates.push({ op: FileOperation.RENAME, path: dstPath, oldPath: srcPath });
              callback(undefined, true);
            }
          });
        }
      }
    });
  }

  public walkTree(path: string, filesOnly: boolean): Promise<WalkInfo[]> {
    return new Promise<WalkInfo[]>((resolve, reject) => {
      // Create a transaction.
      const trans = this.db.transaction(DBFileSystem.osEntriesName, 'readonly');
      trans.onerror = reject;
      trans.onabort = reject;

      const callback: DBCallback = (error: Error | undefined, result: WalkInfo[]) => {
        if (error) {
          reject(error);
        } else {
          resolve(result ?? undefined);
        }
      };

      // Perform the walkTree in this transaction.
      const infos: WalkInfo[] = [];
      if (path === '') {
        this.walkTreeRootInTransaction(infos, filesOnly, trans, callback);
      } else {
        this.walkTreeInTransaction(path, filesOnly, infos, true, trans, callback);
      }
    });
  }

  private walkTreeRootInTransaction(
    infos: WalkInfo[],
    filesOnly: boolean,
    trans: IDBTransaction,
    callback: DBCallback
  ): void {
    const osEntries = trans.objectStore(DBFileSystem.osEntriesName);
    if (!osEntries['getAll']) {
      return this.walkTreeInTransaction('', filesOnly, infos, true, trans, callback);
    }
    const req = osEntries['getAll']();
    req.onsuccess = () => {
      const entries: Entry[] = req.result;
      if (filesOnly) {
        for (const entry of entries) {
          if (entry.idData !== 0) {
            infos.push({ path: entry.path, stat: { isDir: false }, hasChildren: false });
          }
        }
        callback(undefined, infos);
      } else {
        const hasChildren = new Set<string>();
        for (const entry of entries) {
          hasChildren.add(entry.dir);
        }
        callback(
          undefined,
          entries.map((entry) => {
            return {
              path: entry.path,
              stat: { isDir: entry.idData === 0 },
              hasChildren: hasChildren.has(entry.path),
            };
          })
        );
      }
    };
    req.onerror = () => callback(DBFileSystem.errorFromException(req.error!));
  }

  private walkTreeInTransaction(
    path: string,
    filesOnly: boolean,
    infos: WalkInfo[],
    isRoot: boolean,
    trans: IDBTransaction,
    callback: DBCallback
  ): void {
    const handleDirEntry = (entries: DirectoryEntry[], callback: DBCallback) => {
      if (!filesOnly && !isRoot) {
        this.statInTransaction(path, trans, (error: Error | undefined, stat: Stat) => {
          if (error) {
            callback(error);
          } else {
            infos.push({ path, stat, hasChildren: entries?.length > 0 });
            callback();
          }
        });
      } else {
        callback();
      }
    };

    this.readdirInTransaction(
      path,
      trans,
      (error: Error | undefined, entries: DirectoryEntry[]) => {
        if (error) {
          callback(error);
        } else {
          handleDirEntry(entries, (error) => {
            if (error) {
              callback(error);
            }
            if (!entries) {
              callback();
            }
            const iterate = () => {
              if (entries.length === 0) {
                callback(undefined, infos);
              } else {
                const entry = entries.shift()!;
                const childPath = paths.join(path, entry.name);
                if (entry.stat.isDir) {
                  this.walkTreeInTransaction(childPath, filesOnly, infos, false, trans, (error) => {
                    if (error) {
                      callback(error);
                    } else {
                      iterate();
                    }
                  });
                } else {
                  infos.push({ path: childPath, stat: entry.stat, hasChildren: false });
                  iterate();
                }
              }
            };
            iterate();
          });
        }
      }
    );
  }

  public async dispose(): Promise<void> {
    this.db?.close();
    return super.dispose();
  }
}
