// See https://blog.axlight.com/posts/steps-to-develop-global-state-for-react/

import { createGlobalState } from 'react-hooks-global-state';

export const defaultPanelWidth = 270;

const initialState = {
  // Transient
  workbench_saving: false,

  // Persistent
  workbench_showContentPanel: true,
  workbench_contentPanelHeight: 260,
  workbench_showObjectTree: false,
  workbench_objectTreeWidth: defaultPanelWidth,
  workbench_codePanelWidth: 500,
  workbench_showConsole: false,
  workbench_consoleHeight: 200,
  workbench_showInspector: true,
  workbench_inspectorWidth: defaultPanelWidth,
  workbench_showScriptEditor: false,
  workbench_viewportDevice: {
    device: 'Responsive',
    width: undefined,
    height: undefined,
  },
  contentPanel_activeTab: 'Templates',
};

type State = typeof initialState;

export const {
  useGlobalState: useTransientGlobalState,
  getGlobalState,
  setGlobalState: setTransientGlobalState,
} = createGlobalState(initialState);

export function loadGlobalState(): void {
  const json = localStorage.getItem('globalState');
  if (json) {
    const state = JSON.parse(json);
    setState(state);
  }
}

export function saveGlobalState(): void {
  const state = getState();
  localStorage.setItem('globalState', JSON.stringify(state));
}

function getState(): State {
  const state = {} as State;
  for (const key in initialState) {
    (state as any)[key] = getGlobalState(key as any);
  }
  return state;
}

function setState(state: State): void {
  for (const key in initialState) {
    // Use initialState when there is no persisted state.
    const value = key in state ? (state as any)[key] : (initialState as any)[key];

    setTransientGlobalState(key as any, value);
  }
}

function usePersistentGlobalState<StateKey extends keyof State>(
  stateKey: StateKey
): readonly [State[StateKey], (u: import('react').SetStateAction<State[StateKey]>) => void] {
  const [value, setValue] = useTransientGlobalState(stateKey);
  return [
    value,
    (value) => {
      setValue(value);
      saveGlobalState();
    },
  ];
}

function setPersistentGlobalState<StateKey extends keyof State>(
  stateKey: StateKey,
  update: any
): void {
  setTransientGlobalState(stateKey, update);
  saveGlobalState();
}

export const useGlobalState = usePersistentGlobalState;
export const setGlobalState = setPersistentGlobalState;
