import { importProjectsAndModules } from './importer';
import { createReactorFactory, Reactor } from './reactor';
import { updateMethodsObject } from './reactorObject';
import { Project, ProjectInfo, ProjectState } from './runtime';

type Writable<T> = { -readonly [K in keyof T]: T[K] };

export async function loadProject(
  state: ProjectState,
  info?: ProjectInfo,
  mainProject?: Project
): Promise<Project> {
  // TODO: version Reactor along with projects so it can evolve without breaking old projects.
  // TODO: /* webpackIgnore: true */
  //const runtimeUrl = ((state.Imports as any) as { [name: string]: string })?.runtime ?? './defaultruntimeurl.js';
  //const runtimeModule = await import('./reactor' /* webpackChunkName: "runtime" */);

  // Arrays in old (Deck) projects don't have ids and rely on a nextReactorId to know where new ids start.
  const reactorFactory = createReactorFactory((state as any)?.nextReactorId);

  // The first created object is considered the "project" and augmented with ReactorFactory methods.
  // TODO: maybe not and leave it to global Reactor object?

  // The evaluateList will have all ReactorObjects in the tree, depth-first order.
  const newReactors: Reactor[] = [];
  const project = reactorFactory.createReactor<Project>(state, {
    contextualName: info?.title || '!',

    // Capture newly created Reactors so their prototypes can be set after all imports
    // have been imported.
    updateMethods(reactor: Reactor) {
      newReactors.push(reactor);
    },
  });
  project.reactorFactory = reactorFactory;

  // All projects have a reference to the main (root/host/global/etc) project.
  project.mainProject = mainProject || project;

  // TODO:
  if (info) {
    // Funky cast to override readonly.
    (project as Writable<Project>).info = { ...info }; // TODO: not updated as info changes (e.g. after Project save)
  }

  // Wait for all imports before updating Reactor prototypes which may come from them.
  await Promise.all(importProjectsAndModules(project));

  // Add a methods object for each Reactor. Link Reactors to their prototypes.
  for (const reactor of newReactors) {
    (reactor as any)[updateMethodsObject]();
  }

  if (project.initialize) {
    // TODO: this doesn't serve to allow Projects to completely override ReactorFactory because
    // the whole object hierarchy has already been instantiated via the default ReactorFactory.
    try {
      // TODO: Pass WorkbenchAPI in here or stick with global? The global is convenient for
      // console use but workbenchAPI could be given to it as an argument.
      const promise = project.initialize(reactorFactory, {});
      if (promise) {
        await promise;
      }
    } catch (err) {
      console.error(err);
    }
  }

  return project;
}
