import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import HistoryIcon from '@material-ui/icons/History';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrivateIcon from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import ShareIcon from '@material-ui/icons/Share';
import ViewIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import NestedMenuItem from './3rd-party/NestedMenuItem';
import { appName } from './App';
import { largerPreviewWidth, projectPreviewWidth, smallScreenThreshold } from './ProjectList';
import { getResourceUrl, ProjectInfo } from './runtime';

export const titleHeight = 24;

export const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      transition: 'all .1s ease-in-out',
      '&:hover': {
        transform: 'scale(1.15)',
      },
    },
    imageHolder: {
      width: projectPreviewWidth,
      height: projectPreviewWidth,
      [theme.breakpoints.up(smallScreenThreshold)]: {
        width: largerPreviewWidth,
        height: largerPreviewWidth,
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      backgroundColor: 'lightgray',
      display: 'block',
    },
    titleBar: {
      marginTop: '4px',
      position: 'absolute',
      left: 0,
      right: 0,
      height: titleHeight,
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      background: 'rgba(0, 0, 0, 0.05)',
    },
    titleWrap: {
      flexGrow: 1,
      marginLeft: 8,
      marginRight: 0,
      color: theme.palette.common.black,
      overflow: 'hidden',
      cursor: 'pointer',
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    actionIcon: {},
    menuButton: {
      padding: '4px 0px 0px 0px',
      margin: 0,
      color: theme.palette.common.black,
    },
    renameCancelButton: {
      padding: '4px 0px 0px 0px',
      margin: 0,
      color: theme.palette.common.black,
    },
    renameInput: {
      // TODO: to match what's set on the body. Is there a better way to inherit them?
      fontSize: '0.875rem',
      letterSpacing: '0.01071em', // TODO: not having an effect
    },
  })
);

export interface ProjectCardProps {
  projectInfo: ProjectInfo;
  onEdit(projectInfo: ProjectInfo, version?: string): void;
  onPlay(projectInfo: ProjectInfo): void;
  onDuplicate(projectInfo: ProjectInfo): void;
  onDelete?(projectInfo: ProjectInfo): void;
  onPublish?(projectInfo: ProjectInfo): void;
  onUnpublish?(projectInfo: ProjectInfo): void;
  onRename?(projectInfo: ProjectInfo): void;
  onSetSharing?(projectInfo: ProjectInfo, sharing: string): void;
  onExport?(projectInfo: ProjectInfo): void;
}

export const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  const {
    projectInfo,
    onEdit,
    onPlay,
    onDelete,
    onPublish,
    onUnpublish,
    onRename,
    onDuplicate,
    onSetSharing,
    onExport,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [renaming, setRenaming] = useState(false);

  const url = `/p/${projectInfo.ownerName}/${projectInfo.id}/`;

  const share:
    | ((data: { url: string; text: string; title: string }) => void)
    | undefined = (window.navigator as any).share;
  //const subtitle = 'how does it look with a subtitle?';

  const renameInputRef = useCallback<(el: HTMLInputElement) => void>((input) => {
    if (input) {
      input.select();
    }
  }, []);

  let previewUrl;
  // The project has icons get the first one bigger than our preview size.
  // See https://www.w3.org/TR/appmanifest/#sizes-member
  const icons = projectInfo.manifest?.icons;
  if (icons && icons.length > 0) {
    // TODO: find best size
    // TODO: take platform, purpose, type into account?
    previewUrl = icons[0].src;
  }

  if (!previewUrl) {
    previewUrl = getResourceUrl(projectInfo.preview);
  }

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <div className={clsx(classes.imageHolder, classes.grow)} onClick={onPlayClick}>
        <img
          src={previewUrl}
          className={classes.image}
          title={projectInfo.title}
          alt={projectInfo.title}
        />
      </div>
      <div className={classes.titleBar}>
        <div className={classes.titleWrap}>
          {renaming ? (
            <Input
              className={classes.renameInput}
              inputRef={renameInputRef}
              defaultValue={projectInfo.title}
              autoFocus
              disableUnderline
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              onBlur={onRenameCancel}
              onKeyPress={(e) =>
                e.key === 'Enter' && onRenameAccept((e.target as HTMLInputElement).value)
              }
              onKeyDown={(e) => e.key === 'Escape' && onRenameCancel()}
            />
          ) : (
            <div onClick={onEditClick}>{projectInfo.title}</div>
          )}
          {/*subtitle ? <div className={classes.subtitle}>{subtitle}</div> : null*/}
        </div>
        {renaming ? (
          <IconButton
            size='small'
            className={classes.renameCancelButton}
            aria-label='Close'
            onClick={onRenameCancel}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <div className={classes.actionIcon}>
            <MoreVertIcon
              className={classes.menuButton}
              aria-owns={menuOpen ? 'menu-deck' : undefined}
              aria-haspopup='true'
              onClick={(event) => setAnchorEl(event.currentTarget as any)}
              color='inherit'
              style={{
                color: projectInfo.published
                  ? projectInfo.publishedVersion! < projectInfo.version
                    ? 'rgba(255, 255, 0)'
                    : 'rgba(0, 200, 0)'
                  : 'rgba(0, 0, 0)',
              }}
            />
            {/* TODO: pass the menu in so the card doesn't need changes along with menu handling */}
            <Menu
              id='menu-deck'
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={menuOpen}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={onEditClick} dense>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                Edit
              </MenuItem>
              {onRename && (
                <MenuItem onClick={onRenameClick} dense>
                  <ListItemIcon>
                    <span />
                  </ListItemIcon>
                  Rename
                </MenuItem>
              )}
              <MenuItem onClick={onDuplicateClick} dense>
                <ListItemIcon>
                  <DuplicateIcon />
                </ListItemIcon>
                Duplicate
              </MenuItem>
              {onDelete && (
                <MenuItem onClick={onDeleteClick} dense>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              )}
              {projectInfo.owner !== 'anonymous' && <Divider />}
              {share && (
                <MenuItem onClick={onShareClick} dense>
                  <ListItemIcon>
                    <ShareIcon />
                  </ListItemIcon>
                  Share
                </MenuItem>
              )}
              {projectInfo.owner !== 'anonymous' && (
                <MenuItem onClick={onViewClick} dense disabled={!projectInfo.publishedDeck}>
                  <ListItemIcon>
                    <ViewIcon />
                  </ListItemIcon>
                  View Published
                </MenuItem>
              )}
              {onPublish && (
                <MenuItem
                  onClick={onPublishClick}
                  dense
                  disabled={
                    !!projectInfo.publishedDeck &&
                    projectInfo.version <= projectInfo.publishedVersion!
                  }
                >
                  <ListItemIcon>
                    <CloudIcon />
                  </ListItemIcon>
                  {projectInfo.version > projectInfo.publishedVersion!
                    ? 'Publish Changes'
                    : 'Publish'}
                </MenuItem>
              )}
              {onUnpublish && (
                <MenuItem onClick={onUnpublishClick} dense disabled={!projectInfo.publishedDeck}>
                  <ListItemIcon>
                    <CloudOffIcon />
                  </ListItemIcon>
                  Unpublish
                </MenuItem>
              )}
              {menuOpen && projectInfo.publishedHistory && (
                <NestedMenuItem
                  MenuItemProps={{ dense: true }}
                  label={
                    <>
                      <ListItemIcon>
                        <HistoryIcon />
                      </ListItemIcon>
                      History
                    </>
                  }
                  mainMenuOpen={menuOpen}
                >
                  {projectInfo.publishedHistory &&
                    projectInfo.publishedHistory.map((projectHash) => (
                      <MenuItem
                        key={projectHash}
                        dense
                        onClick={() => onHistoryItemClick(projectHash)}
                      >
                        {projectHash}
                      </MenuItem>
                    ))}
                </NestedMenuItem>
              )}
              {onSetSharing && <Divider />}
              {onSetSharing && (
                <MenuItem onClick={onTogglePublic} dense>
                  <ListItemIcon>
                    {projectInfo.sharing === 'private' ? <PublicIcon /> : <PrivateIcon />}
                  </ListItemIcon>
                  {projectInfo.sharing === 'private' ? 'Share with Everyone' : 'Make Private'}
                </MenuItem>
              )}
              {onExport && <Divider />}
              {onExport && (
                <MenuItem onClick={onExportClick} dense>
                  <ListItemIcon></ListItemIcon>
                  Export
                </MenuItem>
              )}
            </Menu>
          </div>
        )}
      </div>
    </div>
  );

  function onHistoryItemClick(projectHash: string): void {
    setAnchorEl(null);
    onEdit(projectInfo, projectHash);
  }

  function onShareClick(_event: React.MouseEvent): void {
    setAnchorEl(null);
    if (share) {
      (window.navigator as any).share({
        url,
        text: `Check out this ${appName} project "${projectInfo.title}".`,
        title: projectInfo.title,
      });
    }
  }

  function onViewClick(): void {
    setAnchorEl(null);
    window.open(url, projectInfo.name);
  }

  function onPublishClick(): void {
    setAnchorEl(null);
    onPublish!(projectInfo);
  }

  function onUnpublishClick(): void {
    setAnchorEl(null);
    onUnpublish!(projectInfo);
  }

  function onTogglePublic(): void {
    setAnchorEl(null);
    onSetSharing!(projectInfo, projectInfo.sharing === 'private' ? 'public' : 'private');
  }

  function onRenameClick(): void {
    setAnchorEl(null);
    setRenaming(true);
  }

  function onRenameCancel(): void {
    setRenaming(false);
  }

  function onRenameAccept(newTitle: string): void {
    setRenaming(false);
    // TODO: any title restrictions? sanitization?
    projectInfo.title = newTitle;
    onRename!(projectInfo);
  }

  function onEditClick(): void {
    setAnchorEl(null);
    onEdit(projectInfo);
  }

  function onPlayClick(): void {
    setAnchorEl(null);
    onPlay(projectInfo);
  }

  function onDeleteClick(): void {
    setAnchorEl(null);
    onDelete!(projectInfo);
  }

  function onDuplicateClick(): void {
    setAnchorEl(null);
    onDuplicate(projectInfo);
  }

  function onExportClick(): void {
    setAnchorEl(null);
    onExport?.(projectInfo);
  }
};
