import { useEffect } from 'react';

export type StringDictionary = { [key: string]: string };
export type UndefinedStringDictionary = { [key: string]: string | undefined };

/**
 * Like useEffect but works with async functions and makes sure that errors will be reported
 */
export function useAsyncEffect(effect: () => Promise<any>, deps?: []) {
  useEffect(() => {
    effect().catch((e) => console.warn('useAsyncEffect error', e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function setCookie(name: string, value: string, days?: number): void {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name: string): string {
  const b = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
  return b ? b.pop()! : '';
}

export function deleteCookie(name: string): void {
  document.cookie = name + '=; Max-Age=-99999999;';
}

export function parseJwt(token: string): { [name: string]: string } {
  const base64Url = token.split('.')[1];
  const base64 = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(base64);
}

export function randomHSL() {
  return `hsla(${~~(256 * Math.random())},30%,80%,0.8)`;
}

// NOTE: Performance of this is terrible.
export function generateUUID(): string {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string): string => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

// Return true if the two objects are shallowly equal.
export function shallowCompare(a: any, b: any): boolean {
  if (a === b) return true;
  if (!a || !b) return false;
  for (const key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

export function suggestName(baseName: string, takenNames: string[], alwaysSuffix = false): string {
  return baseName + suggestSuffix(baseName, takenNames, alwaysSuffix);
}

export function suggestSuffix(
  baseName: string,
  takenNames: string[],
  alwaysSuffix = false,
  space = false
): string {
  let n = 1;

  while (true) {
    const candidateName = !alwaysSuffix ? baseName : baseName + (space ? ' ' : '') + n;
    if (!takenNames.includes(candidateName)) {
      return !alwaysSuffix ? '' : String(n);
    }
    alwaysSuffix = true;
    n++;
  }
}

export function lowercaseFirstLetter(s: string): string {
  return s[0].toLowerCase() + s.slice(1);
}

// Convert "#rrggbb" to "rgb(R, G, B)"
export function inputColorFromColor(c: string | undefined | null): string {
  //console.log('a in:', c);
  if (c === undefined || c === null || c === '') {
    //console.log('a out: rgb(0, 0, 0)');
    return 'rgb(0, 0, 0)';
  }
  const value = parseInt(c.slice(1), 16);
  const out = `a rgb(${(value & 0xff0000) >> 16}, ${(value & 0xff00) > 8}, ${value & 0xff})`;
  //console.log('out:', out);
  return out;
}

export function hexpad(s: string | number): string {
  if (typeof s === 'number') {
    s = s.toString();
  }
  return parseInt(s).toString(16).padStart(2, '0');
}

// Convert "rgb(R, G, B)" to "#rrggbb"
export function colorFromInputColor(ic: string): string {
  //console.log('b in:', ic);
  const out =
    '#' +
    ic
      .slice(4, -1)
      .split(',')
      .map((s) => hexpad(s))
      .join('');
  //console.log('b out:', out);
  return out;
}

export function encode64(buffer: ArrayBuffer): string {
  return btoa(new Uint8Array(buffer).reduce((s, b) => s + String.fromCharCode(b), ''));
}

export function hex(buffer: ArrayBuffer): string {
  return [].map
    .call(new Uint8Array(buffer), (b: number) => ('00' + b.toString(16)).slice(-2))
    .join('');
}

export interface Watcher {
  close(): void;
}

export class WatcherList<T> {
  private callbacks: ((item: T) => void)[] = [];

  newWatcher(callback: (item: T) => void): Watcher {
    this.callbacks.push(callback);
    return { close: this.closeWatcher.bind(this, callback) };
  }

  closeWatcher(callback: (item: T) => void): void {
    const i = this.callbacks.indexOf(callback);
    if (i !== -1) {
      this.callbacks.splice(i, 1);
    }
  }

  notify(item: T): void {
    // Slice to be immune from list mutations during notification callbacks.
    for (const callback of this.callbacks.slice()) {
      try {
        callback(item);
      } catch (err) {
        // ignore
      }
    }
  }

  async dispose(): Promise<void> {
    this.callbacks = [];
  }
}
