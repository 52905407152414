import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useStyles as useProjectCardStyles } from './ProjectCard';
import { useStyles as useProjectListStyles } from './ProjectList';
import { getProjectInfosByTag } from './projectStorage';
import { getResourceUrl, ProjectInfo } from './runtime';

const useStyles = makeStyles(theme =>
  createStyles({
    loading: {
      display: 'flex',
      justifyContent: 'center',
    },
    progress: {
      margin: theme.spacing(2),
    },
  })
);

interface ChooseTemplateDialogProps extends WithMobileDialog {
  onDone(projectInfo?: ProjectInfo, title?: string): void;
}

const ChooseTemplateDialog: React.FC<ChooseTemplateDialogProps> = props => {
  const { fullScreen, onDone } = props;
  const classes = useStyles();
  const listClasses = useProjectListStyles();
  const [projectInfos, setProjectInfos] = useState<ProjectInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getProjectInfosByTag('_template').then(infos => {
      infos = infos ?? [];
      //infos.unshift(emptyProjectInfo);
      setProjectInfos(infos);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={true}
        onClose={() => onDone()}
        aria-labelledby='form-dialog-title'
        /*PaperComponent={DraggablePaper}*/
      >
        <DialogTitle>Choose a template to begin with</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div className={classes.loading}>
              <CircularProgress className={classes.progress} />
            </div>
          ) : (
            <div className={listClasses.root}>
              {projectInfos.map(info => (
                <TemplateCard
                  key={info.id}
                  projectInfo={info}
                  onDone={info => onDone(info, info.title)}
                />
              ))}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDone()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

interface ChooseTemplateProps {
  onDone(projectInfo?: ProjectInfo, title?: string): void;
}

const MobileChooseTemplateDialog = withMobileDialog({ breakpoint: 'xs' })(ChooseTemplateDialog);
export default MobileChooseTemplateDialog;

export const ChooseTemplate: React.FC<ChooseTemplateProps> = props => {
  return <MobileChooseTemplateDialog onDone={props.onDone} />;
};

export interface TemplateCardProps {
  projectInfo: ProjectInfo;
  onDone(projectInfo: ProjectInfo): void;
}

export const TemplateCard: React.FC<TemplateCardProps> = props => {
  const { projectInfo, onDone } = props;
  const classes = useProjectCardStyles();
  const previewUrl = getResourceUrl(projectInfo.preview);

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <div className={clsx(classes.imageHolder, classes.grow)} onClick={() => onDone(projectInfo)}>
        <img
          src={previewUrl}
          className={classes.image}
          title={projectInfo.title}
          alt={projectInfo.title}
        />
      </div>
      <div className={classes.titleBar}>
        <div className={classes.titleWrap}>
          <div onClick={() => onDone(projectInfo)}>{projectInfo.title}</div>
        </div>
      </div>
    </div>
  );
};
