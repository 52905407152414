import { loadProject } from '../runtime';
import { ImportInfo, local, Project, ProjectState, ResourceId } from './runtime';
import { ReactorObject, isReactor } from './reactor';
import { ReactorObjectPrototype } from './reactorObject';

export function getImportProtocolAndSource(importInfo: ImportInfo): string[] {
  if (!importInfo.source) {
    return ['', ''];
  }
  return importInfo.source.split(':');
}

export async function importProjectOrModule(
  importInfo: ImportInfo,
  project: Project
): Promise<any> {
  const imports = project.imports;

  // Release imported module/project/whatever, if already loaded.
  unimportProjectOrModule(importInfo, project);

  if (!isImport(importInfo)) {
    return;
  }

  const [protocol] = getImportProtocolAndSource(importInfo);
  if (!protocol) {
    return;
  }

  // Hang on to these so we'll have them at unimport time even if they've changed.
  // TODO: do we really need them?
  importInfo.importedName = importInfo.name;
  importInfo.importedSource = importInfo.source;

  //console.log(`import ${importInfo.name} (${importInfo.source})`);
  switch (protocol) {
    case 'http':
    case 'https':
      // Make the import promise available to those who want to wait on the import.
      // TODO: or should use event? or both?
      importInfo.promise = import(importInfo.source /* webpackIgnore: true */);
      imports[importInfo.name + 'Promise'] = importInfo.promise;

      // Import!
      return importInfo.promise.then((module: any) => {
        //console.log(`  imported ${importInfo.source}`);
        importInfo.module = module;
        imports[importInfo.name] = module;
      });

    case 'project': {
      const projectHash = importInfo._projectHash!;
      console.assert(projectHash !== undefined);
      importInfo.promise = readRemoteProject(projectHash)
        // TODO: migrateProject
        .then((state) => loadProject(state, { title: importInfo.name } as any, project.mainProject))
        .then((importedProject) => {
          //console.log(`  imported ${importInfo.source}, hash: ${importInfo._projectHash}`);
          //importedProject.designMode = true; // BUGBUG: this can't always be right
          importInfo.importedProject = importedProject;
          imports[importInfo.name] = importedProject;

          // Have the host project inherit the imported project's imports.
          // This way we have one flat list of imports regardless of how deeply projects are nested.
          appendPrototype(imports, importedProject.imports);

          // Mount Workbench contributions adding them to the project's prototype chain.
          if (importedProject.Workbench) {
            // Create the project.Workbench object if it doesn't already exist.
            if (!project.Workbench) {
              project.Workbench = project.createReactor(
                {},
                { contextualName: (project as ReactorObject).__contextualName + '.Workbench' }
              );
            }

            // Append the new project's Workbench to the importing project's Workbench's prototype chain.
            appendPrototype(project.Workbench, importedProject.Workbench);
          }
        })
        .catch((err) => {
          // imp.error = err;
          console.error(err);
        });
      imports[importInfo.name + 'Promise'] = importInfo.promise;
      return importInfo.promise;
    }

    default:
      console.error(`unknown import protocol: ${importInfo.source}`);
  }
}

export function unimportProjectOrModule(imp: ImportInfo, project: Project): void {
  if (imp.module || imp.importedProject) {
    const imports = project.imports;

    //console.log(`unimport ${imp.name} (${imp.source})`);
    delete imp.promise;
    delete imp.module;

    if (imp.importedProject) {
      // Remove the imported project's contributed imports.
      removePrototype(imports, imp.importedProject.imports);

      // Unmount Workbench contributions.
      if (imp.importedProject.Workbench) {
        removePrototype(project.Workbench!, imp.importedProject.Workbench);
      }
    }

    // Clean up.
    delete imports[imp.importedName!];
    delete imports[imp.importedName + 'Promise'];
    delete imp.importedName;
    delete imp.importedSource;
  }
}

export function importProjectsAndModules(project: Project): Promise<any>[] {
  const imports: ImportInfo[] = project.Imports!;
  if (!imports) {
    return [];
  }

  const importPromises: Promise<any>[] = [];
  for (const info of imports) {
    importPromises.push(importProjectOrModule(info, project));
  }
  return importPromises;
}

export function importModules(project: Project): Promise<any>[] {
  const imports: ImportInfo[] = project.Imports!;
  if (!imports) {
    return [];
  }

  const modulePromises: Promise<any>[] = [];
  for (const info of imports) {
    if (isModuleImport(info)) {
      modulePromises.push(importProjectOrModule(info, project));
    }
  }
  return modulePromises;
}

function isModuleImport(info: ImportInfo): boolean {
  if (!isImport(info)) {
    return false;
  }

  const [protocol] = info.source.split(':', 1);
  return protocol === 'http' || protocol === 'https';
}

export function isImport(info: any): boolean {
  if (!info.name || !info.source) {
    return false;
  }
  return true;
}

// TODO: progress
export async function readRemoteProject(resid: ResourceId): Promise<ProjectState> {
  // TODO: error handling
  const resourceUrl = local ? `/backend/resource/${resid}` : `https://resources.viz.site/${resid}`;
  // TODO: error handling
  const response = await fetch(resourceUrl);
  // TODO: error handling
  const jsonText = await response.text();
  const json = deserialize(jsonText);
  return json.state;
}

// TODO: dates, regex
export function deserialize(s: string): any {
  return JSON.parse(s, (_key: string, value: any): any => {
    // ReactorArray ids are encoded as an extra element at the end of the array.
    if (Array.isArray(value) && value.length > 0) {
      const idString = value[value.length - 1];
      if (typeof idString === 'string') {
        if (idString.startsWith('__id:')) {
          (value as any).id = parseInt(idString.split(':')[1]);
          value.pop();
        }
      }
    }
    return value;
  });
}

export async function importJavascriptProjectState(javascript: Blob): Promise<ProjectState> {
  //const dataUri = 'data:text/javascript;charset=utf-8' + encodeURIComponent(javascript);
  const objectUrl = URL.createObjectURL(javascript);
  const state: ProjectState = (await import(objectUrl /* webpackIgnore: true */)).default;

  function convertFunctions(obj: any): any {
    for (const property in obj) {
      const v = obj[property];
      switch (typeof v) {
        case 'function':
          let script = (obj[property] as Function).toString();

          // Remove the indention getSource added.
          const lines = script.split('\n');
          // The last line of the function just has indention and a closing curly brace.
          const indentIndex = lines[lines.length - 1].length - 1;
          const re = new RegExp(`^ {${indentIndex}}`, 'gm');

          // Achieve two-space indent.
          script = script.replace(re, '');

          // Achieve flatness.
          // TODO: instead of having all-methods mode indent/unindent have it be the default
          // and have single method mode indent/unindent.
          // TODO: how to get "Format Document" to work?
          script = script.replace(/^ {2}/gm, '');

          obj[property] = `$function ${script}`;
          break;

        case 'object':
          if (Array.isArray(v)) {
            for (const element of v) {
              if (typeof element === 'object') {
                convertFunctions(element);
              }
            }
          } else {
            convertFunctions(v);
          }
          break;
      }
    }
  }

  convertFunctions(state);
  return state;
}

function appendPrototype(chain: Object, prototype: object): void {
  console.assert(chain !== undefined);
  let chainEnd = chain;
  while (
    isReactor(Object.getPrototypeOf(chainEnd)) &&
    Object.getPrototypeOf(chainEnd) !== ReactorObjectPrototype
  ) {
    chainEnd = Object.getPrototypeOf(chainEnd);
  }
  Object.setPrototypeOf(chainEnd, prototype);
}

function removePrototype(chain: Object, prototype: Object): void {
  console.assert(chain !== undefined);
  let linkBefore = chain;
  while (Object.getPrototypeOf(linkBefore) !== prototype) {
    linkBefore = Object.getPrototypeOf(linkBefore);
  }
  Object.setPrototypeOf(linkBefore, Object.getPrototypeOf(prototype));
}
