import { createMirrorArray } from './mirrorArray';
import {
  ChangeListener,
  ChangeType,
  CreateReactorOptions,
  IDisposable,
  isReactor,
  Properties,
  ReactorArray,
  ReactorFactory,
  ReactorObjectBase,
  templateIsDirty,
  ReactorObject,
} from './reactor';

// Private internals
const _self = Symbol('_self');
const _target = Symbol('_target');
const prototype = Symbol('prototype');
const factory = Symbol('factory');
const dirty = Symbol('dirty');
const changeListeners = Symbol('changeListeners');
const methods = Symbol('methods');
const notifyListeners = Symbol('notifyListeners');
export const updateMethodsObject = Symbol('updateMethodsObject');
const evaluateExpressionProperty = Symbol('evaluateExpressionProperty');
const evaluateExpression = Symbol('evaluateExpression');
const isReactorDirty = Symbol('isReactorDirty');
const createTemplateMutants = Symbol('createTemplateMutants');

interface ReactorObjectInternals extends ReactorObjectBase {
  _isReactor: boolean;
  [_self]: ReactorObjectBase;
  [_target]: ReactorObjectInternals;
  [prototype]?: ReactorObjectBase;
  [factory]: ReactorFactory;
  [dirty]?: Properties;
  [methods]?: Properties;
  [changeListeners]: ChangeListener[];
  templateMutants?: ReactorObjectBase[];

  [notifyListeners](property: PropertyKey, newValue: any, oldValue: any, type: ChangeType): void;
  [isReactorDirty](template: ReactorObjectBase): boolean;
  [evaluateExpression](property: string, expression: any): any;
  [evaluateExpressionProperty](property: string): void;
  [updateMethodsObject](): void;
  [createTemplateMutants](): void;
  //clone(options?: CloneReactorOptions): Reactor;
}

// target, traps, receiver
const reactorObjectTraps = {
  // TODO: rewrite as defineProperty trap? More comprehensive and bullet proof?
  // NOTE: When a proxy is on a prototype chain its set trap is called with the
  // inheriting object as the receiver and the proxy as the target.
  set(_: ReactorObjectInternals, property: string, value: any, receiver: any): boolean {
    // Reactor.id is readonly.
    // TODO: anything else readonly that isn't a symbol property?
    if (property === 'id') {
      return false;
    }

    if (property[0] === '$') {
      // Convert expression property objects to Reactors so they'll have ids, change notifications, etc.
      if (typeof value === 'object' && !isReactor(value)) {
        value = _[factory].createReactor(value);
      }
    }

    // TODO: ? Reflect.get(_, property, receiver);
    const oldValue = (_ as any)[property];

    if (value !== oldValue || (value === undefined && !_.hasOwnProperty(property))) {
      if (property[0] === '$') {
        Reflect.set(_, property, value, receiver);

        // Notify that the expression has changed.
        // TODO: async?
        const changeType = property in _ ? 'change' : 'add';
        _[notifyListeners](property, value, oldValue, changeType);

        // TODO: don't evaluate immediately. Mark dirty and evaluate async (but before view updating).
        if (isMethod(value)) {
          // TODO: If the changed value is a function recreate this instance and any that use it as a template.
          // Recreation means: unmount, initialize, mount. Delete + undo? Only at edit time?
          // Simpler to reload the whole slide?
          _[updateMethodsObject]();

          // New methods might change how the Reactor updates so force full update.
          receiver.invalidate();
        } else {
          _[evaluateExpressionProperty](property);
        }
      } else {
        if (property === 'prototype') {
          _[prototype] = value;
          return Reflect.setPrototypeOf(_[methods] || _, value || ReactorObjectPrototype);
        }

        // Figure out what kind of change it is before we change it!
        const changeType = property in _ ? 'change' : 'add';

        // Use Reflect.defineProperty instead of Relect.set will cause the set trap to be
        // called again the proxy is on a prototype chain.
        Reflect.defineProperty(receiver, property, {
          value,
          writable: true,
          configurable: true,
          enumerable: true,
        });

        // Underscore-prefixed properties and symbols don't cause notifications or invalidation.
        // TODO: just have them live inside a "private" object?
        if (typeof property !== 'symbol' && property[0] !== '_') {
          receiver.invalidate(property);
          // TODO: async?
          _[notifyListeners](property, value, oldValue, changeType);
        }
      }
    }

    return true;
  },

  deleteProperty(_: ReactorObjectInternals, property: string): boolean {
    // TODO: ? Reflect.get(_, property, receiver);
    const oldValue = (_ as any)[property];
    if (property[0] === '$') {
      if (Reflect.deleteProperty(_, property)) {
        // This causes the deleteProperty trap to be called again (case below).
        return delete (_[_self] as any)[property.slice(1)];
      } else {
        return false;
      }
    }

    const success = Reflect.deleteProperty(_, property);
    if (success) {
      // Underscore-prefixed properties and symbols don't cause notifications or invalidation.
      if (typeof property !== 'symbol' && property[0] !== '_') {
        _[_self].invalidate(property);
        // TODO: async?
        _[notifyListeners](property, undefined, oldValue, 'remove');
      }
    }
    return success;
  },

  /* TODO: setPrototypeOf trap
    // Change to prototype invalidates the whole object (and objects dependent on this object).
    // TODO: And also remount? If so this is not the place.
    if (property === 'prototype') {
      receiver.invalidate();
    }
    */

  getOwnPropertyDescriptor(
    _: ReactorObjectInternals,
    property: PropertyKey
  ): PropertyDescriptor | undefined {
    const descriptor = Reflect.getOwnPropertyDescriptor(_, property);
    if (descriptor) {
      return descriptor;
    }

    // We relocate methods to a prototype object but still want them to appear as
    // part of the instance.
    if (_[methods]) {
      return Reflect.getOwnPropertyDescriptor(_[methods]!, property);
    }
    return undefined;
  },

  // TODO: do this in getOwnPropertyDescriptor trap instead?
  // Filter out properties end-users did not explicitly create (aka "surprise properties").
  // This includes expression ($-prefixed) properties, project, __contextualName, etc.
  ownKeys(_: ReactorObjectInternals): PropertyKey[] {
    let keys = Reflect.ownKeys(_);

    // Methods are on the object's prototype (methods object).
    if (_[methods]) {
      keys = keys.concat(...Reflect.ownKeys(_[methods]!));
    }

    return keys.filter((key) => {
      if (typeof key === 'symbol' || (typeof key === 'string' && key[0] === '$')) {
        return false;
      }
      return true;
    });
  },
};

// NOTE: using "this", other than for private properties, prevents inheritors from overriding
// that usage of the property. See /(this as any)/, etc.

export const ReactorObjectPrototype: ReactorObjectInternals = {
  // This the prototype so all these live on the instance.
  // We declare them here anyway to make Typescript happy.
  _isReactor: true,
  __contextualName: undefined!,
  id: undefined!,
  [_self]: undefined!,
  [_target]: undefined!,
  [prototype]: undefined!,
  [factory]: undefined!,
  [changeListeners]: undefined!,

  // TODO:
  [createTemplateMutants]: undefined!,

  getState(): Properties {
    const state: Properties = {};
    const expressions = this.getOwnExpressions();
    for (const property in expressions) {
      const value = expressions[property];
      state[property.slice(1)] = isReactor(value) ? value.getState() : value;
    }
    return state;
  },

  dispose(): void {
    console.assert(this.id !== 0); // Already disposed?

    const expressions = this.getExpressions();
    for (const key in expressions) {
      const value = expressions[key];
      if (isReactor(value)) {
        value.dispose();
      }
    }

    delete this[factory].reactors[this.id];
    (this as any).$id = 0;
  },

  onPropertyChange(listener: ChangeListener): IDisposable {
    const listeners = this[changeListeners] || [];
    listeners.push(listener);
    this[changeListeners] = listeners;
    return {
      dispose: () => listeners.splice(listeners.indexOf(listener)),
    };
  },

  getExpressions(): Properties {
    let expressions = this.getOwnExpressions();

    let prototype = Reflect.getPrototypeOf(this) as any;
    while (prototype && isReactor(prototype)) {
      // First occurance of a property 'wins'.
      expressions = Object.assign({}, prototype.getOwnExpressions(), expressions);
      prototype = Reflect.getPrototypeOf(prototype);
    }
    return expressions;
  },

  getOwnExpressions(): Properties {
    // TODO: use this or this[_self]?
    const ownExpressionPropertyNames = Object.keys(this[_target] || this).filter(
      (property) => property[0] === '$'
    );
    const ownExpressions: Properties = {};
    for (const property of ownExpressionPropertyNames) {
      // TODO: use this or this[_self]?
      ownExpressions[property] = (this as any)[property];
    }
    return ownExpressions;
  },

  // If the Reactor is dirty call its update method with the dirty properties.
  // Clear the dirty property tracking object.
  validate(): void {
    // Invalidate all properties of this Reactor if its prototype is invalid.
    if (this[prototype]) {
      if (this[isReactorDirty](this[prototype]!)) {
        this[_self].invalidate();
      }
    }

    // We don't want to inherit the dirty properties object.
    const descriptor = Object.getOwnPropertyDescriptor(this, dirty);
    const _dirty = descriptor?.value;
    if (_dirty) {
      if (this[_self].update) {
        this[dirty] = undefined;
        try {
          this[_self].update!(_dirty || {});
        } catch (err) {
          // TODO: make this apparent in the Workbench somehow
          console.error(err);
        }
      }
    }
  },

  // Invalidate all properties or just a specific one.
  invalidate(property?: string): void {
    // We don't want to inherit the dirty properties object.
    const descriptor = Object.getOwnPropertyDescriptor(this, dirty);
    const _dirty = descriptor?.value || {};
    if (property) {
      _dirty[property] = true;
    } else {
      // No property specified. Invalidate all enumerable ones (including inherited).
      for (const property in this) {
        // Underscore prefixed properties, prototype, and id aren't dirty tracked.
        if (property[0] !== '_' && property !== 'id' && property !== 'prototype') {
          _dirty[property] = true;
        }
      }
    }
    this[dirty] = _dirty;
  },

  clearDirty(): void {
    this[dirty] = undefined;
  },

  /*
    // Make a clone of a Reactor. This clones expression properties only, no value properties.
    clone(options?: CloneReactorOptions): Reactor {
      const state = this.getState();
      removeIds(state);
      return this[factory].createReactor(state, {
        contextualName: options?.contextualName || (this[__contextualName] || '') + '_clone',
      });
    },
  */

  evaluateExpressionProperties(): void {
    // Want to execute in context of target, not proxy.
    // TODO: true of other public methods as well?
    if (this === this[_self]) {
      this[_target].evaluateExpressionProperties();
      return;
    }

    const expressions = this.getOwnExpressions();
    for (const property in expressions) {
      this[evaluateExpressionProperty](property);
    }
  },

  [evaluateExpressionProperty](property: string): void {
    let expression = (this as any)[property];
    const valueProperty = property.slice(1);
    if (isMethod(expression) || property === '$prototype') {
      // Don't evaluate method properties.
      return;
    } else if (property === '$id') {
      // Don't evaluate id property, pass it on as a value.
      (this as any)[valueProperty] = expression;
    } else if (Array.isArray(expression)) {
      // Make a copy of the array so changes to it won't be persisted. Changes to its element's
      // expression properties ARE persisted.
      // TODO: only do this if it has changed
      (this[_self] as any)[valueProperty] = createMirrorArray(
        this[factory],
        expression as ReactorArray
      );
    } else {
      const oldValue = (this[_self] as any)[valueProperty];
      const value = this[evaluateExpression](property, expression);
      if (
        value !== oldValue ||
        (value === undefined && !this[_self].hasOwnProperty(valueProperty))
      ) {
        // So ReactorComponent's set trap can invalidate.
        // TODO: do we need this any more (as opposed to values[property] = value)
        // The difference is that this invalidates and causes a change event to be fired.
        (this[_self] as any)[valueProperty] = value;
      }
    }
  },

  [evaluateExpression](property: string, expression: any): any {
    function wrapper() {
      // TODO: totally unsafe, etc
      // TODO: scope
      // TODO: console
      // eslint-disable-next-line no-eval
      return eval(expression.slice(1));
    }

    let value = expression;

    // TODO: evaluate
    if (typeof expression === 'string') {
      switch (expression[0]) {
        // Formula expressions
        case '=':
          try {
            value = wrapper.call(this[_self]);
          } catch (err) {
            console.error(err);
            value = undefined;
          }
          break;

        // Other special expressions
        case '$':
          // TODO:
          break;

        // All others are returned unchanged as the value.
      }
    }
    return value;
  },

  [notifyListeners](property: PropertyKey, newValue: any, oldValue: any, type: ChangeType): void {
    // TODO: batch? asyncify?
    if (this[changeListeners]) {
      for (const listener of this[changeListeners]) {
        listener(this[_self], property, newValue, oldValue, type);
      }
    }
  },

  // TODO: consider adding all dirty Reactors to isDirty WeakMap as they're dirtied.
  // Then can be used to for other is-something-I'm-dependent-on-dirty tests.
  [isReactorDirty](reactor: ReactorObjectBase): boolean {
    // Do we already know this template to be dirty?
    let isDirty = templateIsDirty.get(reactor);
    if (isDirty) {
      // Yes.
      return true;
    } else if (isDirty === undefined) {
      // Unknown. Check if it is dirty.
      if (reactor) {
        // HACK: this "as any" is because https://stackoverflow.com/q/59118271/707320
        // TODO: expose an isDirty to the public?
        isDirty = (reactor as any)[dirty] !== undefined;
        if (isDirty) {
          // Remember that it's dirty.
          templateIsDirty.set(reactor, isDirty);
          return true;
        }

        // It's not dirty but if a template it inherits from is dirty we'll count it as dirty.
        if ((reactor as any)[prototype]) {
          if (this[isReactorDirty]((reactor as any)[prototype])) {
            return true;
          }
        }
      }
      // Remember that it's not dirty.
      templateIsDirty.set(reactor, false);
    }

    return false;
  },

  // Create a "methods object" with all the Reactor's methods. Set it as the prototype
  // of the value object. Set the methods object's prototype to be a Proxy that looks up
  // requested properties in the Reactor's templates.
  // This structure allows methods to use "super" to call methods inherited from templates.
  [updateMethodsObject](): void {
    const methodStrings: string[] = [];
    const expressions = this.getOwnExpressions();
    for (const property in expressions) {
      let expression = expressions[property];
      // Function expressions
      if (isMethod(expression)) {
        // TODO: $getfunction. $setfunction?
        const { script, params } = extractScript(expression);
        const valueProperty = property.slice(1);
        methodStrings.push(`${valueProperty}(${params}) {\n${script}\n}`);
      }
    }

    try {
      // Only create the methods object if there are some methods.
      if (methodStrings.length !== 0) {
        const name = ((this.__contextualName || expressions.$name || 'id') + '.' + expressions.$id)
          .replace(/ /g, '-')
          .replace(/\//g, ':');
        // eslint-disable-next-line no-new-func
        const createMethodsObject = new Function(
          'console',
          'Reactor',
          `// ${name}\nreturn {\n${methodStrings.join(',\n\n')}}\n//# sourceURL=${name}.js`
        );
        const m = createMethodsObject(console /* TODO: should be workbenchConsole */, {
          getObject: this[factory].getReactorByPath.bind(this[factory]),
        });

        this[methods] = m;
        Reflect.setPrototypeOf(this, this[methods]!);
      } else {
        this[methods] = undefined;
      }

      // Resolve the prototype.
      this[prototype] = undefined;
      const $prototype = expressions.$prototype;
      if ($prototype) {
        this[prototype] = this[factory].getTemplate($prototype);
        if (this[prototype] === undefined) {
          console.error(`unable to getTemplate ${$prototype}`);
        }
      }

      // Link the methods into the prototype chain.
      Reflect.setPrototypeOf(this[methods] || this, this[prototype] || ReactorObjectPrototype);
    } catch (err) {
      //console.error(err);
    }
  },
};

// Make all the internal Reactor properties non-enumerable (same behavior as Object).
const descriptors = Object.getOwnPropertyDescriptors(ReactorObjectPrototype);
for (const property in descriptors) {
  descriptors[property].enumerable = false;
  descriptors[property].configurable = false; // Supposedly helps perf.
}
Object.defineProperties(ReactorObjectPrototype, descriptors);

export function createReactorObject(
  _factory: ReactorFactory,
  properties?: Properties,
  options?: CreateReactorOptions
): ReactorObjectBase {
  // Copy properties because we might change them.
  properties = { ...properties };

  // TODO: set up inheritance chain.
  let prototype: ReactorObjectBase = ReactorObjectPrototype;

  // TODO: better name for target
  const target = Object.create(prototype);
  target[_target] = target;

  const self = new Proxy(target, reactorObjectTraps);
  target[_self] = self; // TODO: or "proxy"?
  target[factory] = _factory;

  // Backwards compatibility with multiple inheritance templates.
  // TODO: remove after all projects migrated to use prototype instead.
  if (properties?.templates?.[0]) {
    properties.prototype = properties.templates[0];
    // TODO: remove templates? or just keep ignoring them?
    //delete properties.templates;
  }

  // Reactor will already have an id if it is being deserialized or recreated by undo/redo.
  let id;
  if (properties?.id) {
    // Be sure generated ids don't overlap existing ids.
    // BUGBUG: when id-less Reactors are created before the highest id is found they
    // will be assigned a (potentially) colliding id.
    id = properties.id;
    if (id >= _factory.nextReactorId) {
      _factory.nextReactorId = id + 1;
    }
  } else {
    id = _factory.nextReactorId++;
  }

  console.assert(_factory.reactors[id] === undefined, `Reactor id ${id} already in use!`);
  _factory.reactors[id] = self;
  target.$id = id;

  // Set here so there is an instance property for evaluateExpressionProperties to set into.
  // Otherwise it will fail in Proxy set trap because id is read-only.
  target.id = id;

  // Retain the root Reactor. This is the one assumed to be the Project and that
  // Reactor paths are relative to. Augment the project with ReactorFactory methods.
  if (_factory.rootReactor === undefined) {
    target.createReactor = _factory.createReactor.bind(_factory);
    target.getReactorById = _factory.getReactorById.bind(_factory);
    target.getReactorByPath = _factory.getReactorByPath.bind(_factory);
    target.forEachReactor = _factory.forEachReactor.bind(_factory);
    _factory.rootReactor = self;

    // Old projects (Decks) record their highest id. Use it so auto-created (e.g. templates)
    // ReactorArrays don't aquire conflicting ids.
    if (properties?.nextReactorId) {
      _factory.nextReactorId = properties.nextReactorId;
    }

    // Initialize Project with an empty resolved imports object.
    target.imports = _factory.createReactor(
      {},
      { contextualName: (options?.contextualName || '') + '.imports' }
    );
  }

  target.project = _factory.rootReactor;
  Object.defineProperty(target, 'project', {
    enumerable: false,
    configurable: true,
    writable: true,
  });

  // Backwards compatibility with old projects.
  target._project = _factory.rootReactor;
  Object.defineProperty(target, '_project', {
    enumerable: false,
    configurable: true,
    writable: true,
  });

  if (options?.contextualName) {
    Object.defineProperty(target, '__contextualName', { value: options.contextualName });
  }

  // Copy initial properties as expressions and recursively create child objects as Reactors.
  if (properties) {
    for (const property in properties) {
      let value = properties[property];
      if (typeof value === 'object') {
        value = _factory.createReactor(value, {
          contextualName: (target.__contextualName ? target.__contextualName + '.' : '') + property,
          updateMethods: options?.updateMethods,
        });
      }
      target['$' + property] = value;
    }
  }

  if (options?.updateMethods) {
    options.updateMethods(self);
  } else {
    target[updateMethodsObject]();
  }

  target.evaluateExpressionProperties();

  return self;
}

export function extractScript(expression: string): { script: string; params: string } {
  const paramsStart = expression.indexOf('(') + 1;
  const paramsEnd = expression.indexOf(')');
  let bodyStart = expression.indexOf('{') + 1;
  if (expression[bodyStart] === '\n') {
    bodyStart++;
  }
  const bodyEnd = expression.lastIndexOf('}');
  const params = expression.slice(paramsStart, paramsEnd).trim();
  const script = expression.slice(bodyStart, bodyEnd);
  /*
  console.log('expression:');
  console.log(expression);
  console.log('params:');
  console.log(params);
  console.log('script:');
  console.log(script);
  */
  return { params, script };
}

function isMethod(expression: string): boolean {
  return (
    typeof expression === 'string' &&
    (expression.startsWith('$function') || expression.startsWith('$getfunction'))
  );
}

export function getAllValuesOfProperty(
  reactor: ReactorObject,
  property: string
): {
  values: any[] | undefined;
  templateNames: string[] | undefined;
  templateObjects: ReactorObject[] | undefined;
} {
  const values = [];
  const templateNames = [];
  const templateObjects = [];

  let templateName = 'this';

  while (reactor) {
    if (reactor.hasOwnProperty(property)) {
      values.push(reactor[property]);
      templateNames.push(templateName);
      templateObjects.push(reactor);
    }

    templateName = reactor.$prototype;
    reactor = Reflect.getPrototypeOf(reactor) as ReactorObject;
  }

  if (values.length === 0) {
    return { values: undefined, templateNames: undefined, templateObjects: undefined };
  }
  return { values, templateNames, templateObjects };
}
