import { db } from './firebase';
import { UserId } from './runtime';
import { PublicUser, User } from './user';

export class UserService {
  private users: { [userId: string]: PublicUser } = {};
  private userIds: { [userName: string]: UserId } = {};
  private currentUser: User | undefined = undefined;

  setCurrentUser(user: User | undefined): void {
    this.currentUser = user;
    if (user) {
      this.users[user.id] = { id: user.id, name: user.name };
      this.userIds[user.name] = user.id;
    }
  }

  getCurrentUser(): User | undefined {
    return this.currentUser;
  }

  async getUserId(userName: string): Promise<UserId | undefined> {
    // Try the cache first.
    let userId = this.userIds[userName];
    if (userId) {
      return userId;
    }

    /* TODO: can do it this way too but
        1) requires ".indexOn": ["name"] rule and
        2) can't do a case insensitive lookup.
    db.ref('users')
      .orderByChild('name')
      .equalTo(userName)
      .once('value')
      .then(snapshot => {
        const user = snapshot.val() as PublicUser;
        if (!user) {
          setError(`Unknown user: ${userName}`);
        } else {
          setUserId(user.id);
        }
      });
      */
    const snapshot = await db.ref(`userids/${userName.toLowerCase()}`).once('value');
    userId = snapshot.val();
    if (!userId) {
      return undefined;
    } else {
      // Add to cache.
      this.userIds[userName] = userId;
      return userId;
    }
  }

  async getPublicUsers(): Promise<PublicUser[]> {
    const snapshot = await db.ref(`users`).once('value');
    const userMap: { [uid: string]: { created: number; name: string } } = snapshot.val();
    const users = Object.keys(userMap).map(
      uid => ({ id: uid, name: userMap[uid].name } as PublicUser)
    );

    // Add to cache.
    users.forEach(user => (this.users[user.id] = user));
    return users;
  }
}

export async function isNameTaken(db: firebase.database.Database, name: string): Promise<boolean> {
  const snapshot = await db.ref(`userids/${name.toLowerCase()}`).once('value');
  return snapshot.exists();
}
