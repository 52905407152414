import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { ProjectCard, titleHeight } from './ProjectCard';
import { ProjectPlayer } from './ProjectPlayer';
import { ProjectStore } from './projectStorage';
import { ProjectInfo } from './runtime';

export const projectPreviewWidth = 144;
export const largerPreviewWidth = 170;
export const smallScreenThreshold = 361;

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fill, ${projectPreviewWidth}px)`,
      gridAutoRows: `${projectPreviewWidth + titleHeight + 8}px`,
      [theme.breakpoints.up(smallScreenThreshold)]: {
        gridTemplateColumns: `repeat(auto-fill, ${largerPreviewWidth}px)`,
        gridAutoRows: `${largerPreviewWidth + titleHeight + 8}px`,
      },
      gridGap: theme.spacing(2),
      padding: 0,
      justifyContent: 'center',
      gridAutoFlow: 'dense',
      //backgroundColor: theme.palette.background.paper,
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
    },
    progress: {
      margin: theme.spacing(2),
    },
  })
);

export interface ProjectListProps {
  projectInfos: ProjectInfo[];
  projectStore: ProjectStore;
  onEdit(projectInfo: ProjectInfo, version?: string): void;
  onDuplicate(projectInfo: ProjectInfo): void;
  onPublish?(projectInfo: ProjectInfo): void;
  onUnpublish?(projectInfo: ProjectInfo): void;
  onSetSharing?(projectInfo: ProjectInfo, sharing: string): void;
  onRename?(projectInfo: ProjectInfo): void;
  onDelete?(projectInfo: ProjectInfo): void;
  onExport?(projectInfo: ProjectInfo): void;
}

export const ProjectList: React.FC<ProjectListProps> = (props) => {
  const {
    projectInfos,
    projectStore,
    onEdit,
    onPublish,
    onUnpublish,
    onDuplicate,
    onSetSharing,
    onRename,
    onDelete,
    onExport,
  } = props;
  const classes = useStyles();
  const [deleteProjectInfo, setDeleteProjectInfo] = useState<ProjectInfo | undefined>();
  const [renameProjectInfo, setRenameProjectInfo] = useState<ProjectInfo | undefined>();
  const [ioError, setIoError] = useState('');
  const [confirmDeleteProjectInfo, setConfirmDeleteProjectInfo] = useState<
    ProjectInfo | undefined
  >();
  const [playProjectInfo, setPlayProjectInfo] = useState<ProjectInfo>();

  /* TODO: differentiate between
  - browser forward or back to deck reference
  - no deck reference
  - deck reference but while DeckPlayer closing
  // If the URL indicates the DeckPlayer should be shown BUT there is no playDeckInfo
  // we know doing the funky thing.
  const [location] = useLocation();
  if (!playDeckInfo) {
    const url = new URL(window.location.href);
    console.log(url.pathname, location);
    if (url.pathname !== location) {
      const matches = url.pathname.match(/\/p\/([^/]+)\/([^/]+)/);
      if (&& matches?.length === 3) {
        const [, userId, deckId] = matches;
        console.log(`userId: ${userId}, deckId: ${deckId}`);
        deckStore.getDeckInfoAsync(userId, deckId).then(deckInfo => setPlayDeckInfo(deckInfo));
      }
    }
  }
  */

  useEffect(() => {
    if (deleteProjectInfo) {
      setDeleteProjectInfo(undefined);
      projectStore.deleteProject(deleteProjectInfo.id).catch((err) => {
        setIoError(err.toString());
      });
      onDelete!(deleteProjectInfo);
    }
  }, [projectStore, projectInfos, deleteProjectInfo, onDelete]);

  useEffect(() => {
    if (renameProjectInfo) {
      setRenameProjectInfo(undefined);
      projectStore.writeProjectInfo(renameProjectInfo).catch((err) => {
        setIoError(err.toString());
      });
      onRename!(renameProjectInfo);
    }
  }, [projectStore, renameProjectInfo, onRename]);

  return (
    <div>
      {ioError && <div>{ioError}</div>}
      {playProjectInfo && (
        <ProjectPlayer
          projectInfo={playProjectInfo}
          projectStore={projectStore}
          onClose={() => setPlayProjectInfo(undefined)}
          onEdit={() => {
            setPlayProjectInfo(undefined);
            onEdit(playProjectInfo);
          }}
        />
      )}
      {deleteProjectInfo && (
        <Dialog
          open={true}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Deleting {deleteProjectInfo.title}</DialogTitle>
          <DialogContent>
            <div className={classes.loading}>
              <CircularProgress className={classes.progress} />
            </div>
          </DialogContent>
        </Dialog>
      )}
      <div className={classes.root}>
        {projectInfos.map((info) => (
          <ProjectCard
            key={info.id}
            projectInfo={info}
            onEdit={onEdit}
            onPlay={(projectInfo) => setPlayProjectInfo(projectInfo)}
            onDelete={onDelete && onDeleteProject}
            onPublish={onPublish}
            onUnpublish={onUnpublish}
            onRename={onRename && onRenameProject}
            onDuplicate={onDuplicate}
            onSetSharing={onSetSharing}
            onExport={onExport}
          />
        ))}
      </div>
      {confirmDeleteProjectInfo && (
        <ConfirmDeleteDialog
          projectInfo={confirmDeleteProjectInfo}
          onClose={onConfirmDeleteDialogClose}
        />
      )}
    </div>
  );

  function onDeleteProject(projectInfo: ProjectInfo): void {
    setConfirmDeleteProjectInfo(projectInfo);
  }

  function onRenameProject(projectInfo: ProjectInfo): void {
    setRenameProjectInfo(projectInfo);
  }

  function onConfirmDeleteDialogClose(confirm: boolean): void {
    if (confirm) {
      setDeleteProjectInfo(confirmDeleteProjectInfo);
    }
    setConfirmDeleteProjectInfo(undefined);
  }
};

interface ConfirmDeleteDialogProps {
  projectInfo: ProjectInfo;
  onClose(confirmed: boolean): void;
}

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = (props) => {
  const { projectInfo, onClose } = props;

  return (
    <Dialog
      open={true}
      onClose={() => onClose(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Delete {projectInfo.title}?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>This can not be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color='primary'>
          Cancel
        </Button>
        <Button onClick={() => onClose(true)} color='primary' autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
